// const map_settings = frappe.provide('frappe.utils.map_defaults');

// map_settings.center = [39.7600, -98.5000]; // center of the US
// map_settings.zoom = 6;


$.extend(frappe.utils.map_defaults, {
	center: [39.7600, -98.5000],
	zoom: 6,
});
