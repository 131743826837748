
	frappe.templates['store_reservations'] = `{%
let headerRows = tableData.headerRows;
let reservationRows = tableData.reservationRows;
let totalRows = tableData.totalRows;
let sortedColors = Object.keys(tableData.reservation_type_colors).sort().reduce(
    (obj, key) => {
        obj[key] = tableData.reservation_type_colors[key]; return obj;
    },
    {}
);
%}


<div class="dividers-store-reservations">
<table class="store-reservations reservations-by-month">
    <thead>
        <tr>
            {%= headerRows.monthRow.join("") %}
        </tr>
        <tr>
            {%= headerRows.cycleRow.join("") %}
        </tr>
        <tr>
            {%= headerRows.dayRow.join("") %}
        </tr>
    </thead>
    <tbody>
        {% reservationRows.forEach(r => { %}
        <tr>
            {%= r.join("") %}
        </tr>
        {% }); %}
        <tr class="totals">
            {%= totalRows.join("") %}
        </tr>
    </tbody>
</table>
<!-- 
{%
let key = "";

for (const [k, v] of Object.entries(sortedColors)) {
    link = frappe.utils.get_form_link("Dividers Reservation Type", k);
}
%} -->
<div class="reservations-color-key">
    <p><strong>Key:</strong></p>
    <div>
        <div>
            <span class="reservation">Contract</span>
            <span class="continuation cancelled">Cancelled Continuation</span>
            <span class="continuation active">Active Continuation</span>
        </div>
        <div>
            {% for (const [k, v] of Object.entries(sortedColors)) { %}
                {%
                link = frappe.utils.get_form_link("Dividers Reservation Type", k);
             
                %}
                <span style="background-color:{%= v %};">
                    <a style="color: blackOrWhite(v);"  href="{%= link %}">{%= k %}</a>
                </span>  
            {% } %}
        </div>
    </div>
    <p>* <em>Total Includes Edges</em></p>
</div>



  `;
