
	frappe.templates['sales_order_details'] = `<div class="clearfix"></div>
<div class="address-box">
    <p class="h6 flex align-center">
        {%= name %}
    </p>
    <p>
        <div class="flex">
            <span class="pr-1">{%= __("Customer") %}:</span>
            <span>{%= customer %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Contact") %}:</span>
            <span>{%= contact_display %}</span>
        </div>
        {% if (production_number) { %}
        <div class="flex">
            <span class="pr-1">{%= __("Production No") %}:</span>
            <span>{%= production_number %}</span>
        </div>
        {% } %}
    </p>
    <p>
        <div class="flex">
            <span class="pr-1">{%= __("Company") %}:</span>
            <span>{%= company %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Status") %}:</span>
            <span>{%= status %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Transaction Date") %}:</span>
            <span>{%= transaction_date %}</span>
        </div>
    </p>
    <hr>
    <p>
        <div class="h6">{%= __("Reservations") %}:</div>
        <div style="max-height:200px;overflow-y:auto;">
        {% for (const item of items) { %}
            {% if (item.item_name.startsWith("RSVN-")) { %}
            <div class="flex">
                <span class="pr-1">{%= item.item_name %}:</span>
                <span>{%= item.qty %}</span>
            </div>
            {% } %}
        {% } %}
        </div>
    </p>
    <hr>
    <p>
        <div class="flex">
            <span class="pr-1">{%= __("Sales Partner") %}:</span>
            <span>{%= sales_partner %}</span>
        </div>
        {% if (sales_team.length > 0) { %}
        <div class="flex">
            <span class="pr-1">{%= __("Sales&nbsp;Team") %}:</span>
            <span>
                {% for (const rep of sales_team) { %}
                    {%= rep.sales_person %}<br>
                {% } %}
            </span>
        </div>
        {% } %}
    </p>
    <p>
        {% if (payment_terms_template) { %}
        <div class="flex">
            <span class="pr-1">{%= __("Payment Terms") %}:</span>
            <span>{%= payment_terms_template %}</span>
        </div>
        {% } %}
        <div class="flex">
            <span class="pr-1">{%= __("Grand Total") %}:</span>
            <span>$ {%= grand_total %}</span>
        </div>
    </p>
</div>
`;
