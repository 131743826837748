
	frappe.templates['contact'] = `<div class="clearfix"></div>
<div class="address-box">
    <p class="h6 flex align-center">
        {%= (typeof first_name === "undefined") ? "No first name" : first_name %}
        {%= (typeof last_name === "undefined") ? "No last name" : last_name %}
        {% if (typeof is_primary_contact !== "undefined" && is_primary_contact) { %}
            <span class="text-muted">&nbsp;(Primary)</span>
        {% } %}
    </p>
    {% if (typeof phone_nos !== "undefined" && typeof email_ids !== undefined) { %}
    <p>
        {% if (phone) { %}
        <div class="flex">
            <span class="pr-1">
                {%= __("Phone") %}:
            </span>
            <span>
                {%= phone %} <span class="text-muted"> ({%= __("Primary") %})</span><br>
            </span>
        </div>
        {% } %}
        {% if (mobile_no) { %}
        <div class="flex">
            <span class="pr-1">
                {%= __("Mobile No") %}:
            </span>
            <span>
                {%= mobile_no %} <span class="text-muted"> ({%= __("Primary") %})</span><br>
            </span>
        </div>
        {% } %}
        {% if (phone_nos) { %}
            {% for (const phone_no of phone_nos) { %}
                {% if (!(phone_no.is_primary_phone || phone_no.is_prmary_mobile_no)) { %}
                <div class="flex">
                    <span class="pr-1">
                        {%= __("Phone") %}:
                    </span>
                    <span>
                        {%= phone_no.phone %}
                    </span>
                </div>
                {% } %}
            {% } %}
        {% } %}
    </p>
    <p>
        {% if (email_id) { %}
        <div class="flex">
            <span class="pr-1">
                {%= __("Email") %}:
            </span>
            <span>
                {%= email_id %} <span class="text-muted"> ({%= __("Primary") %})</span><br>
            </span>
        </div>
        {% } %}
        {% if (email_ids) { %}
            {% for (const email_id of email_ids) { %}
                {% if (!email_id.is_primary) { %}
                <div class="flex">
                    <span class="pr-1">
                        {%= __("Email") %}:
                    </span>
                    <span>
                        {%= email_id.email_id %}
                    </span>
                </div>
                {% } %}
            {% } %}
        {% } %}
    </p>
    {% } %}
    <p>
        {% if (typeof address !== "undefined" && address) { %}
        <div class="flex">
            <span class="pr-1">
                {%= __("Address") %}:
            </span>
            <span>
                {%= address %}<br>
            </span>
        </div>
        {% } %}
    </p>
</div>
`;
