frappe.provide('dividers');

// lightblue among other colors are not available - test!
dividers.reservationColors = {
    disabled    : 'gray',
    notActive   : 'pink',
    completed   : 'yellow',
    active      : 'blue',
    autorenewal : 'cyan',
    cancelled   : 'orange',
    undefined   : 'red',
}

window.blackOrWhite = (hex) => {
    const hexToRGB = (h) => {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (h.length == 4) {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];

            // 6 digits
        } else if (h.length == 7) {
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
        }

        return [+r, +g, +b];
    }

    const rgb2hsv = (r,g,b) => {
        var computedH = 0;
        var computedS = 0;
        var computedV = 0;

        //remove spaces from input RGB values, convert to int
        var r = parseInt( (''+r).replace(/\s/g,''),10 ); 
        var g = parseInt( (''+g).replace(/\s/g,''),10 ); 
        var b = parseInt( (''+b).replace(/\s/g,''),10 ); 

        if ( r==null || g==null || b==null ||
              isNaN(r) || isNaN(g)|| isNaN(b) ) {
            alert ('Please enter numeric RGB values!');
            return;
        }
        if (r<0 || g<0 || b<0 || r>255 || g>255 || b>255) {
            alert ('RGB values must be in the range 0 to 255.');
            return;
        }
        r=r/255; g=g/255; b=b/255;
        var minRGB = Math.min(r,Math.min(g,b));
        var maxRGB = Math.max(r,Math.max(g,b));

        // Black-gray-white
        if (minRGB==maxRGB) {
            computedV = minRGB;
            return [0,0,computedV];
        }

        // Colors other than black-gray-white:
        var d = (r==minRGB) ? g-b : ((b==minRGB) ? r-g : b-r);
        var h = (r==minRGB) ? 3 : ((b==minRGB) ? 1 : 5);
        computedH = 60*(h - d/(maxRGB - minRGB));
        computedS = (maxRGB - minRGB)/maxRGB;
        computedV = maxRGB;
        return [computedH,computedS,computedV];
    }

    let rgb = hexToRGB(hex);
    let hsv = rgb2hsv(...rgb);
    if (hsv[2] < .5) return 'white';
    return 'black';
}
