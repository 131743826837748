frappe.provide('farmers');


farmers.SearchDialog = class FarmersSearchDialog {
    get resultsEl() {
        return this.searchDialog.wrapper[0]
        .querySelector('.frappe-control[data-fieldname="search_results"]')
    }

    /**
     * @param {function} selectionCb    The callback to handle the selected agent's data
     * @param {String} dialogTitle      A title for the search dialog
     */
    constructor(selectionCb, opts) {
        this.selectionCb = selectionCb;
        this.dialogTitle='Search for a Farmers Agent'
        this.aorNumber = '';
        this.emailAddress = '';
        if (opts) $.extend(this, opts);
        this.clickEventListener = (e) => {
            const agentData = JSON.parse(
                decodeURI(e.target.closest('.farmers-agent-result').dataset.agent)
            );
            this.hide();
            selectionCb(agentData);
        };
        this.searchDialog = this.makeSearchDialog();
    }

    makeSearchDialog() {
        return new frappe.ui.Dialog({
            title: this.dialogTitle,
            fields: [
                {
                    label: 'Agent AOR Number',
                    fieldname: 'aor_number',
                    fieldtype: 'Data',
                    default: this.aorNumber,
                }, {
                    fieldtype: 'Column Break',
                }, {
                    label: 'Agent Email Address',
                    fieldname: 'email',
                    fieldtype: 'Data',
                    options: 'Email',
                    default: this.emailAddress,
                }, {
                    fieldtype: 'Section Break',
                }, {
                    fieldname: 'search_results',
                    fieldtype: 'HTML',
                }
            ],
            size: 'large',
            primary_action_label: 'Search',
            primary_action: (r) => this.search(r),
            on_page_show: () => this._setupClickEvent(),
        });
    }

    show() {
        this.searchDialog.show();
        this._setupClickEvent();
    }

    hide() {
        this.searchDialog.hide();
        this._tearDownClickEvent();
    }

    _setupClickEvent() {
        if (this.resultsEl) {
            dividers.addEventForChild(
                this.resultsEl, 'click', '.farmers-agent-result', this.clickEventListener);
        }
    }

    _tearDownClickEvent() {
        this.resultsEl.removeEventListener('click', this.clickEventListener)
    }

    search(values) {
        frappe.call({
            method: 'dividers.system.doctype.farmers_settings.farmers_settings.search_vendor_agents',
            args: {agent_aor: values.aor_number, agent_email: values.email},
        }).then(({message: results}) => {
            this.showSearchResults(results);
        });
    }

    clearSearchResults() {
        this.resultsEl.replaceChildren();
    }

    appendToSearchResults(html) {
        this.clearSearchResults();
        this.resultsEl.insertAdjacentHTML('beforeend', html);
    }

    showSearchResults(results) {
        if (results.results.length === 0) {
            this.appendToSearchResults('No results');
            return;
        }

        var resultsEls = [];
        for (const result of results.results) {
            const renderedResult = frappe.render_template('farmers_agent_result', {...result, result: result});
            resultsEls.push(renderedResult);
        }

        const resultsTable = `<table class="w-100">
            <tbody>
                <tr><td>${resultsEls.join('</td></tr><tr><td>')}</td></tr>
            </tbody>
        </table>`;

        this.appendToSearchResults(resultsTable);
    }
}