
	frappe.templates['reservation_extensions'] = `<div class="simple-table dividers-reservation-extensions">
<table class="reservation-extensions">
    <thead>
        <th width="15%">Type</th>
        <th width="15%">Start</th>
        <th width="15%">Length</th>
        <th width="15%">End</th>
        <th width="40%">Notes</ht>
    </thead>
    <tbody>
        {% extensions.forEach(e => { %}
        <tr>
        	<td>{%= e.type %}</td>
        	<td>
        	{% if (e.start_cycle) { %}
        		{%= e.start_cycle %}
        	{% } else { %}
        		{%= e.start_date %}
        	{% } %}
        	</td>
        	<td>{%= e.extension_length %}</td>
        	<td>
        	{% if (e.end_cycle) { %}
        		{%= e.end_cycle %}
        	{% } else { %}
        		{%= e.end_date %}
        	{% } %}
       		</td>
        	<td>{%= e.notes %}</td>
        </tr>
        {% }); %}
    </tbody>
</table>
</div>
`;
