frappe.provide('dividers.reservation');


Date.prototype.clone = function() { return new Date(this.getTime()); };

Date.prototype.addDays = function(days) {
    let newDate = new Date(this.getTime());
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};


Date.prototype.daysInMonth = function() {
    // getMonth() is 0 indexed, but 1 indexed when creating a new Date
    return new Date(this.getYear(), this.getMonth() + 1, 0).getDate();
};

dividers.reservation.buildReservationsTableData = (data) => {
    const table = new dividers.reservation.ReservationsTable(data);
    return table.build();
};

/**
 * Build and return the data for the template
 */
dividers.reservation.ReservationsTable = class {
    constructor(data) {
        this.dt = frappe.datetime;
        this.totalsDetail = {};
        this.metadata = {
            days: 0,
            firstDay: null,
            lastDay: null,
        };
        this.data = data;
        this.headerRows = {
            cycleRow: [],
            monthRow: [],
            dayRow: [],
        };
        this._rsvnRows = [];
        this.totalRow = [];
    }

    build() {
        this.buildHeaderRows();
        this.buildReservationRows();
        this.buildTotalRow();

        const tableData = {
            headerRows              : this.headerRows,
            reservationRows         : this._rsvnRows,
            totalRows               : this.totalRow,
            reservation_type_colors : this.data.reservation_type_colors,
        };
        
        console.log("table data", tableData)


        return tableData;
    }

    /**
     * Create the table header rows
     */
    buildHeaderRows() {
        /**
         * Create a header row with Month names
         */
        const addMonthHeader = (config) => {
            const formatDate = (d) => d.toLocaleDateString(
                'en-US', { month: 'short', year: 'numeric', });
            let columns = 0;

            let row = [`<th>Month</th>`];
            let mDate = config.start.clone();
            mDate.setDate(1);
            // set up a filler span for the remainder of the first month
            if (config.start.getDate() !== 1) {
                let daysLeft = mDate.daysInMonth() - config.start.getDate() + 1;
                row.push(`<th colspan="${daysLeft}"></th>`);
                mDate.setMonth(mDate.getMonth() + 1);
                columns += daysLeft;
            }
            // set up spans for each month
            while ((mDate.getYear() < config.end.getYear())
                || (mDate.getYear() === config.end.getYear() &&
                    mDate.getMonth() < config.end.getMonth())
            ) {
                columns += mDate.daysInMonth();
                row.push(`<th colspan="${mDate.daysInMonth()}">
                    ${formatDate(mDate)}
                </th>`);
                mDate.setMonth(mDate.getMonth() + 1);
            }
            // set up a filler span for the remainder of the last month
            if (mDate < config.end) {
                mDate.setMonth(mDate.getMonth() + 1);
                row.push(`<th colspan="${config.end.getDate()}"></th>`);
                columns += config.end.getDate();
            }
            this.headerRows.monthRow = row;
        };

        /**
         * Create a header row with cycle names
         */
        const addCycleHeader = (config) => {
            let row = [`<th>Cycle</th>`];
            let columns = 0;
            if (config.daysBefore > 0) {
                row.push(`<th colspan="${config.daysBefore}"></th>`);
                columns += config.daysBefore;
            }
            config.cycles.forEach(c => row.push(`<th colspan="28">${c}</th>`));
            columns += config.cycles.length * 28;
            if (config.daysAfter > 0) {
                row.push(`<th colspan="${config.daysAfter}"></th>`);
                columns += config.daysAfter;
            }
            this.headerRows.cycleRow = row;
        };

        /**
         * Create a header row with days
         */
        const addDayHeader = (config) => {
            let row = new Array(config.days + 1);
            row.fill('<th></th>');
            const today = this.dt.get_day_diff(this.dt.get_today(), this.metadata.firstDay);
            row[today] = '<th class="today"></th>';
            this.headerRows.dayRow = row;
        };

        const cycles = Object.keys(this.data.cycles);

        const cyclesStart = this.dt.str_to_obj(this.data.cycles[cycles[0]]);
        let cyclesEnd = this.dt
            .str_to_obj(this.data.cycles[cycles[cycles.length - 1]])
            .addDays(27);

        const firstDate = (this.dt.str_to_obj(this.data.start) < cyclesStart)
            ? this.dt.str_to_obj(this.data.start)
            : cyclesStart;
        const lastDate = (this.dt.str_to_obj(this.data.end) > cyclesEnd)
            ? this.dt.str_to_obj(this.data.end)
            : cyclesEnd;

        const monthRowConfig = {
            start : firstDate,
            end   : lastDate,
        };
        const cycleRowConfig = {
            daysBefore : this.dt.get_day_diff(cyclesStart, monthRowConfig.start),
            daysAfter  : this.dt.get_day_diff(monthRowConfig.end, cyclesEnd),
            cycles     : cycles,
        };
        const dayRowConfig = {
            days: this.dt.get_day_diff(monthRowConfig.end, monthRowConfig.start) + 1,
        };
        this.metadata.days = dayRowConfig.days;
        this.metadata.firstDay = monthRowConfig.start;
        this.metadata.lastDay = monthRowConfig.end;

        addCycleHeader(cycleRowConfig);
        addMonthHeader(monthRowConfig);
        addDayHeader(dayRowConfig);
    }

    /**
     * Create the reservation and extension rows
     */
    buildReservationRows() {
        /**
         * Group reservations so that reservations don't overlap
         */
        const sortCustomerReservationsIntoRows = (rsvns) => {
            const addRsvn = (rowIdx, rsvn) => {
                if (!(rowIdx in sortedRsvns)) {
                    sortedRsvns[rowIdx] = [];
                }
                sortedRsvns[rowIdx].push(rsvn);
            };

            var sortedRsvns = [];
            var rowIdx = 0;
            var endDate;

            for (const rsvn of rsvns) {
                if (!(rowIdx in sortedRsvns) ||
                    (endDate && endDate < rsvn.start_date)
                ) {
                    endDate = rsvn.end_date;
                    addRsvn(rowIdx, rsvn);
                } else {
                    rowIdx++;
                    addRsvn(rowIdx, rsvn);
                }
            }
            return sortedRsvns;
        };
        /**
         * A simple function for sorting reservations and extensions
         */
        const compare_start_dates = (a, b) => {
            if (a.start_date < b.start_date) return -1;
            if (a.start_date > b.start_date) return 1;
            return 0;
        }

        var sortedCustomerRsvns = {};
        var customerHasExtensions = false;
        for (const [_customer, _customerRsvns] of Object.entries(this.data.reservations)) {
            _customerRsvns.sort(compare_start_dates);
            _customerRsvns.forEach(r => r.extensions.sort(compare_start_dates));
            let rsvns = _customerRsvns.slice();
            sortedCustomerRsvns[_customer] = sortCustomerReservationsIntoRows(rsvns);
        }

        for (const customerRsvns of Object.values(sortedCustomerRsvns)) {
            this._buildCustomerRowsHeader(customerRsvns);
            this._buildRsvnRowsForCustomer(customerRsvns);
        }
    }

    /**
     * Create a new reservation row
     */
    addRsvnRow() {
        this._rsvnRows.push([]);
    }

    /**
     * Add a cell to the currently active reservation row
     * 
     * @param {String} value    The cell contents
     * @param {Boolean} newRow  If true, insert the value into a new row
     */
    addToRsvnRow(value=null, newRow=false) {
        if (newRow) this.addRsvnRow();
        this._rsvnRows[this._rsvnRows.length - 1].push(value);
    }

    /**
     * Build the column dedicated to the customer name. This column can
     * spans multiple rows, so that there is only once cell even if there
     * are multiple rows dedicated to the customer.
     *
     * @param {Array} customerRsvns     Reservations belonging to a single
     *                                  customer, sorted into rows
     */
    _buildCustomerRowsHeader(customerRsvns) {
        // Count the number of rows that will be dedicated to this customer.
        // We will use this number as a rowspan for the header.
        var rowQty = customerRsvns.length;
        for (const rsvns of customerRsvns) {
            // check if any of the reservations in a row contain an extension
            if (rsvns.some(r => (r.extensions.length) > 0)
            ) {
                // add a row to handle the extensions
                rowQty++;
            }
        }

        let exampleRsvn = customerRsvns[0][0]
        let customerLink = exampleRsvn.customer_link;
        let customerName = exampleRsvn.customer_name;
        this.addToRsvnRow(
            `<td class="header-cell" rowspan="${rowQty}">
                <div>
                    <a
                        href="${customerLink}"
                        title="${customerName}"
                        data-doctype="Customer"
                        data-name="${exampleRsvn.customer}"
                    >
                        ${customerName}
                    </a>
                </div>
            </td>`, true);
    }

    /**
     * Build all rows for a single customer
     * 
     * @param {Object} customerRsvns    The resrevations for a customer
     */
    _buildRsvnRowsForCustomer(customerRsvns) {
        let rowClass = '';
        let ignore = true;
        for (const rsvns of Object.values(customerRsvns)) {
            if (ignore) {
                ignore = false;
            } else {
                this.addRsvnRow();
            }
            this._buildReservationRow(rsvns, rowClass);
            rowClass = 'cust-extra';  // other rows for the same customer
        }
    }

    /**
     * Build a single row of reservations, as well as associated extensions
     * 
     * @param {Array}  rsvns    The list of reservations belonging to a row
     * @param {String} rowClass A class associated with the row
     */
    _buildReservationRow(rsvns, rowClass) {
        /**
         * Add an empty cell that spans until the start of the next cell
         * 
         * @param {Moment}  nextCellStart   The start date for the next cell
         * @param {cellClass} cellClass     A class associated with the row
         */
        const addFreeSpace = (nextCellStart, cellClass) => {
            if (nextCellStart > currentPosition) {
                let colsToFill = this.dt.get_day_diff(nextCellStart, currentPosition);
                if (colsToFill < 1) return;

                const _addFreeSpace = (colsToFill, cellClass) => {
                    this.addToRsvnRow(
                        `<td class="${cellClass}" colspan="${colsToFill}"></td>`
                    );
                };

                const maxWidth = this.getMaxWidth(colsToFill, 1000);
                for (var i=0; i < Math.floor(colsToFill / maxWidth); i++) {
                    _addFreeSpace(maxWidth, cellClass);
                }
                const remainder = colsToFill % maxWidth;
                if (remainder) {
                    _addFreeSpace(remainder, cellClass);
                }
            }
        }
        /**
         * Add cell contents to a cell, and add the cell to a row
         * 
         * @param {String}  cellContents    The cell contents
         * @param {Integer} colspan         The number of cells to span, i.e.
         *                                  the width of the cell
         * @param {String}  cellClass       Any class to associate with the cell
         */
        const addToRow = (cellContents, colspan, cellClass) => {
            if (colspan < 1) return;
            // HTML spec specifies a max colspan of 1000
            const _addToRow = (cellContents, colspan, cellClass) => {
                this.addToRsvnRow(
                    `<td class="${cellClass}"
                         colspan="${colspan}"
                    >
                        ${cellContents}
                    </td>`
                );
            }

            const maxWidth = this.getMaxWidth(colspan, 1000);
            for (var i=0; i < Math.floor(colspan / maxWidth); i++) {
                _addToRow(cellContents, maxWidth, cellClass);
            }
            const remainder = colspan % maxWidth;
            if (remainder) {
                _addToRow(cellContents, remainder, cellClass);
            }
        };

        /**
         * Create a cell title from a reservation or extension
         * 
         * @param {Object}  rsvn    The reservation
         * @param {Boolean} ar      Return a description for autorenewal instead
         * @param {Object}  extn    Return a description for an extension
         *                          instead
         */
        const makeTitle = (rsvn, ar=false, extn=null) => {
            var range;
            var plural;
            if (ar) {
                const start = (rsvn.period_type === 'Cycle')
                    ? rsvn.autorenewal_start_cycle
                    : this.dt.obj_to_str(rsvn.autorenewal_start_date);
                if (rsvn.autorenewal === 'active') {
                    return `Autorenewal (${rsvn.autorenewal}) starting ${start}`;
                }

                const end = (rsvn.period_type === 'Cycle')
                    ? rsvn.autorenewal_end_cycle
                    : this.dt.obj_to_str(rsvn.autorenewal_end_date);
                return `Autorenewal (${rsvn.autorenewal}), ${start} - ${end}`;
            } else if (extn) {
                // 2 month covid 1232 - 1234
                plural = (parseInt(extn.extension_length) > 1) ? 's' : '';
                if ((parseInt(extn.extension_length) > 1)) {
                    range = (rsvn.period_type === 'Cycle')
                        ? `${extn.start_cycle} - ${extn.end_cycle}`
                        : `${this.dt.obj_to_str(extn.start_date)} - ${this.dt.obj_to_str(extn.end_date)}`;
                } else {
                    range = (rsvn.period_type === 'Cycle')
                        ? extn.start_cycle
                        : this.dt.obj_to_str(extn.start_date);
                }
                return `${extn.extension_length} ${rsvn.period_type}${plural}, ${range}`;
            }
            plural = (parseInt(rsvn.reservation_length) > 1) ? 's' : '';
            range = (rsvn.period_type === 'Cycle')
                ? `${rsvn.rsvn_start_cycle} - ${rsvn.rsvn_end_cycle}`
                : `${this.dt.obj_to_str(rsvn.rsvn_start_date)} - ${this.dt.obj_to_str(rsvn.rsvn_end_date)}`;
            return `${rsvn.reservation_length} ${rsvn.period_type}${plural}, ${range}`;
        };

        /**
         * Add a resrvation to a row
         *
         * @param {Object}  rsvn    The reservation
         */
        const addRsvnToRow = (rsvn) => {
            const makeLinkContent = (rsvn, colspan) => {
                const plural = (parseInt(rsvn.spaces) > 1) ? 's' : '';
                let content = `<span>${rsvn.spaces} ${rsvn.surface}${plural}</span>`;

                if (colspan >= 260) {
                    content = content.repeat(Math.ceil(colspan/25/8));
                }
                return `<span>${content}</span>`;
            };
            addFreeSpace(rsvn.start_date, rowClass);
            const rsvnLength = this.dt.get_day_diff(
                rsvn.rsvn_end_date, rsvn.start_date) + 1;

            let title = makeTitle(rsvn);
            let contents = this.buildLink(rsvn.rsvn, title, '', makeLinkContent(rsvn, rsvnLength));
            addToRow(contents, rsvnLength, `reservation ${rowClass}`);

            if (rsvn.autorenewal_start_date) {
                let atrnlEnd = rsvn.autorenewal_end_date || this.metadata.lastDay;
                let atrnlLength = this.dt.get_day_diff(
                    atrnlEnd, rsvn.autorenewal_start_date) + 1;

                let title = makeTitle(rsvn, true);
                contents = this.buildLink(rsvn.rsvn, title, '', makeLinkContent(rsvn, atrnlLength));
                addToRow(contents, atrnlLength, `autorenewal ${rsvn.autorenewal} ${rowClass}`);
            }
            if (rsvn.end_date) {
                // if there is no end date, currentPosition will be reset
                // on the next row, so we don't have to worry about it here
                currentPosition = rsvn.end_date.addDays(1);
            }
        };
        /**
         * Add an extension to a row
         * 
         * @param {Object}  rsvn    The extension's parent reservation
         * @param {Object}  extn    The extension
         */
        const addExtnToRow = (rsvn, extn) => {
            addFreeSpace(extn.start_date, 'cust-extra');
            const extnLength = this.dt.get_day_diff(
                extn.end_date, extn.start_date) + 1;

            const bgColor = extn.color;
            const txtColor = blackOrWhite(bgColor);

            const title = makeTitle(rsvn, false, extn);
            const contents = this.buildLink(rsvn.rsvn, title,
                `background-color:${bgColor};color:${txtColor}`, extn.type);
            addToRow(contents, extnLength, 'extension cust-extra');
            currentPosition = extn.end_date.addDays(1);
        };


        // build the reservation row
        var currentPosition = this.metadata.firstDay;
        for (const rsvn of rsvns) {
            rsvn.start_date = this.dt.str_to_obj(rsvn.start_date);
            if (rsvn.end_date) {
                rsvn.end_date = this.dt.str_to_obj(rsvn.end_date);
            }
            rsvn.rsvn_start_date = this.dt.str_to_obj(rsvn.rsvn_start_date);
            rsvn.rsvn_end_date = this.dt.str_to_obj(rsvn.rsvn_end_date);
            addRsvnToRow(rsvn);
        }
        addFreeSpace(this.metadata.lastDay, rowClass);

        // build the extension row
        currentPosition = this.metadata.firstDay;
        var hasRsvnRow = false;
        for (const rsvn of rsvns) {
            for (const extn of rsvn.extensions) {
                if (!hasRsvnRow) {
                    this.addRsvnRow();
                    hasRsvnRow = true;
                }
                extn.start_date = this.dt.str_to_obj(extn.start_date);
                if (extn.end_date) {
                    extn.end_date = this.dt.str_to_obj(extn.end_date);
                }
                addExtnToRow(rsvn, extn);
            }
        }
        addFreeSpace(this.metadata.lastDay, 'cust-extra');
    }

    /**
     * Build a row that tallies the number of reservations in the store
     * for a day
     */
    buildTotalRow() {
        /**
         * Calculate the totals for the given date
         *
         * @param {date}    d   The date to gather totals for
         * @return {Object} Total edge and faces
         */
        const getTotalForDate = (d) => {
            let total = {
                edge: 0,
                face: 0,
            };
            // filter the list of reservations to those that contain our date
            const inRange = rsvns.filter(r => {
                return !((r.end !== null && r.end < d) || r.start > d);
            });
            inRange.forEach(r => {
                // add up totals
                switch(r.surface) {
                    case 'Face':
                        total.face += parseInt(r.spaces);
                        break;
                    case 'Edge':
                        total.edge += parseInt(r.spaces);
                        break;
                }
            });
            return total;
        };

        /**
         * Find either the day after the first reservation to end (after the
         * given date), or the first day of the next reservation (that ends
         * after the given date)
         *
         * @param {date}    d   The returned date must be after this date
         * @return  {date}  The first date that meets the criteria for nextDate
         */
        const getNextDate = (d) => {
            let nextDate = this.dt.str_to_obj(this.data.end);
            nextDate.setDate(nextDate.getDate() + 1);
            rsvns.forEach(r => {
                if (r.start > d && r.start < nextDate) {
                    nextDate = r.start.clone();
                }
                if (r.end > d && r.end < nextDate) {
                    nextDate = r.end.clone();
                    nextDate.setDate(nextDate.getDate() + 1);
                }
            });
            return nextDate;
        };

        /**
         * Add a column to the total row
         * @param {Date}    start       The start date of column
         * @param {Object}  total       Total faces and edges
         * @param {Date}    dayAfterEnd The date following the column
         */
        const makeCell = ({start, total}, dayAfterEnd) => {
            const colspan = this.dt.get_day_diff(dayAfterEnd, start);
            if (colspan < 1) return;
            let contents;

            let shortTally = '';
            let longTally = '';
            for (const [surface, txt] of Object.entries({face: 'Faces', edge: 'Edges'})) {
                if (total[surface] > 0) shortTally += total[surface];
                if (total[surface] > 0) longTally += `${txt}: ${total.face}`;
                if (surface == 'edge' && total[surface] > 0) {
                    shortTally += '*';
                    longTally += '*';
                }
            }

            const _makeCell = (start, total, colspan) => {
                if (total.face || total.edge) {
                    if (colspan < 25) {
                        contents = shortTally;
                    } else if (colspan < 260) {
                        contents = `<span>${longTally}</span>`;
                    } else {
                        contents = `<span>${longTally}</span>`
                            .repeat(Math.ceil(colspan/25/8));
                    }
                    contents = `<span>${contents}</span>`;
                } else {
                    contents = '';
                }

                const className = (shortTally.length > 0) ? 'class="total"' : ''
                this.totalRow.push(`<td ${className} colspan="${colspan}"
                    title="Faces: ${total.face}, Edges: ${total.edge}"
                >${contents}</td>`);
            };

            const maxWidth = this.getMaxWidth(colspan, 1000);
            for (var i=0; i < Math.floor(colspan / maxWidth); i++) {
                _makeCell(start, total, maxWidth);
            }
            const remainder = colspan % maxWidth;
            if (remainder) {
                _makeCell(start, total, remainder);
            }
        };

        // start the row
        this.totalRow.push(`<td class="header-cell">Total</td>`);

        // collect a one-dimensional array of reserations
        let rsvns = [];
        for (const [cust, reservations] of Object.entries(this.data.reservations)) {
            reservations.forEach(r => rsvns.push({
                start   : r.start_date,
                end     : r.end_date,
                spaces  : r.spaces,
                surface : r.surface,
            }));
        }

        // collect information for the first total span
        let startDate = this.dt.str_to_obj(this.data.start);
        let spanData = {
            start: startDate,
            total: getTotalForDate(startDate),
        }
        let nextStartDate = getNextDate(startDate);
        const lastDay = this.dt.str_to_obj(this.data.end);
        while (nextStartDate.getTime() < lastDay.getTime()) {
            const total = getTotalForDate(nextStartDate);
            if (spanData.total.edge === total.edge
                && spanData.total.face === total.face) {
                // there were no changes to the totals, so skip
                // over this cell
                nextStartDate = getNextDate(nextStartDate);
                continue;
            }

            // write a totals cell
            makeCell(spanData, nextStartDate);

            // set up for the next total span
            spanData = {
                start: nextStartDate,
                total: total,
            }
            nextStartDate = getNextDate(nextStartDate);
        }
        // write the last totals cell
        makeCell(spanData, nextStartDate);
    }

    /**
     * Return a link to the reservation
     * 
     * @param {String} name     The name of a Dividers Reservation document
     * @param {String} title    The link title
     * @param {String} style    CSS rules to apply to the link
     * @param {String} displayText  The clickable link text
     */
    buildLink(name, title, style, displayText) {
        const route = frappe.utils.get_form_link('Dividers Reservation', name);
        return `<a
            title="${title}"
            style="${style}"
            href="${route}"
            data-doctype="Dividers Reservation"
            data-name="${name}"
        >
            ${displayText}
        </a>`;
    }

    /**
     * Spans cannot be larger than 1000 per the HTML spec.
     * Do some math so that we can properly display reservations longer
     * thatn 1000
     * 
     * @param {int} x   The desired colspan
     * @param {int} n   The largest allowed colspan
     */
    getMaxWidth(x, n) {
        if (x < n) return x;
        var parts = 1;
        // reserve some space so we always have a readable cell
        var quotient = x - 168;
        while (quotient > n) {
            quotient = quotient / ++parts;
        }
        return Math.floor(quotient);
    }
};