
	frappe.templates['farmers_agent_result'] = `<div class="farmers-agent-result p-3 border-bottom" data-agent="{%= encodeURI(JSON.stringify(result)) %}">
    <div>
        <div class="d-inline-block align-top mb-1 w-50">
            <div class="d-inline-block align-top mr-3">
                {% if (thumbnail_url) { %}
                <img src="{%= thumbnail_url %}" class="rounded-circle" width="100" height="100">
                {% } else if (photo_url) { %}
                <img src="{%= photo_url %}" class="rounded-circle" width="100" height="100">
                {% } else if (backup_photo_url) { %}
                <img src="{%= backup_photo_url %}" class="rounded-circle" width="100" height="100">
                {% } else { %}
                <div class="rounded-circle" style="width:100px;height:100px;background-color:var(--avatar-frame-bg);"></div>
                {% endif %}
            </div>
            <div class="d-inline-block align-top">
                <div class="font-weight-bold">{%= agent_full_name %}</div>
                <div class="font-weight-bold">{%= agency_name %}</div>
                <div class="mt-1">
                    <div>{%= address %}</div>
                    <div>{%= city %}, {%= state %} {%= zip %}</div>
                </div>
            </div>
        </div>
        <div class="d-inline-block align-top mb-1">
            <table class="">
                <tbody>
                    <tr><th>Active</th><td class="pl-2">{%= (active) ? "yes" : "no" %}</td></tr>
                    <tr><th>AOR Number</th><td class="pl-2">{%= aor_number %}</td></tr>
                    <tr><th>UPN Number</th><td class="pl-2">{%= upn_number %}</td></tr>
                    <tr><th>Agent Type</th><td class="pl-2">{%= agent_type %}</td></tr>
                    <tr><th>Folio Eligible</th><td class="pl-2">{%= (folio_eligible) ? "yes" : "no" %}</td></tr>
                </tbody>
            </table>
        </div>
    </div>
    <div>
        <div class="d-inline-block align-top">
            <table class="">
                <tbody>
                    {% if (mobile_phone) { %}
                    <tr><th>Mobile Phone</th><td class="pl-2">{%= mobile_phone %}</td></tr>
                    {% endif %}
                    {% if (office_phone) { %}
                    <tr><th>Office Phone</th><td class="pl-2">{%= office_phone %}</td></tr>
                    {% endif %}
                    {% if (email) { %}
                    <tr><th>Email Address</th><td class="pl-2">{%= email %}</td></tr>
                    {% endif %}
                    {% if (website_url) { %}
                    <tr><th>Website URL</th><td class="pl-2">{%= website_url %}</td></tr>
                    {% endif %}
                </tbody>
            </table>
        </div>
    </div>
</div>`;
