frappe.provide('dividers');

dividers.get_document_utils = {
	/**
	 * Return a promise containing the Sales Order object
	 *
	 * @param {String}  salesOrder     The Sales Order name
	 *
	 * @returns {Promise} the Sales Order
	 */
	getSalesOrder: function(salesOrder) {
		return this.getDoc('Sales Order', salesOrder);
	},


	/**
	 * Return a promise containing the Dividers Contract object
	 *
	 * @param {String}  dividersContract     The Dividers Contract name
	 *
	 * @returns {Promise} the Dividers Contract
	 */
	getDividersContract: function(dividersContract) {
		return this.getDoc('Dividers Contract', dividersContract);
	},

	/**
	 * Return a promise containing the Dividers Reservation object
	 *
	 * @param {String}  dividersReservation     The Dividers Reservation name
	 *
	 * @returns {Promise} the Dividers Reservation
	 */
	getDividersReservation: function(dividersReservation) {
		return this.getDoc('Dividers Reservation', dividersReservation);
	},


	/**
	 * Return a promise containing the Project object
	 *
	 * @param {String}  project     The Project name
	 *
	 * @returns {Promise} the Project
	 */
	getProject: function(project) {
		return this.getDoc('Project', project)
	},


	/**
	 * Return a promise containing the Task object
	 *
	 * @param {String}  task     The Task name
	 *
	 * @returns {Promise} the Task
	 */
	getTask: function(task) {
		return this.getDoc('Task', task);
	},


	/**
	 * Returna promise containing the Customer object
	 *
	 * @param {String}  customer     The Customer name
	 *
	 * @returns {Promise} the Customer
	 */
	getCustomer: function(customer) {
		return this.getDoc('Customer', customer);
	},


	/**
	 * Returna promise containing the Contact object
	 *
	 * @param {String}  contact     The Contact name
	 *
	 * @returns {Promise} the Contact
	 */
	getContact: function(contact) {
		return this.getDoc('Contact', contact);
	},


	/**
	 * Return a promise containing the requested document
	 *
	 * @param {String}  doctype  The DocType name
	 * @param {String}  docname  The name of the document to retrieve
	 *
	 * @returns {Promise} the Task
	 */
	getDoc: function(doctype, docname) {
	    const getDocFromLocals = () => {
	        return new Promise((resolve, reject) => {
	            if (doctype in locals
	                && docname
	                && docname in locals[doctype]
	            ) {
                    resolve(locals[doctype][docname]);
	            } else {
	            	reject();
	            }
	        });
	    };
	    const getDocFromBackend = () => {
	        if (docname) {
	            return frappe.db.get_doc(doctype, docname);
	        }
	    }

	    return getDocFromLocals().catch(
	        () => getDocFromBackend()
	    );
	},
};
