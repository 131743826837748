import AuthorizeNetPaymentPlugin from './payment_plugins/authorize_net';

$(document).ready(function () {
    var ws;
    let query_params = frappe.utils.get_query_params();
    let wrapper = $(".web-form-wrapper");
    let eContractDoctype = wrapper.data('e-contract-doctype');
    let eContractName = wrapper.data('e-contract-name');
    let paymentErrorMessage = wrapper.data('payment-error-message');
    let demo = wrapper.data('e-contract-demo');
    let canSign = wrapper.data('e-contract-signer');

    var splitPaymentMethodPath = `dividers.www.starstix_contract${(demo) ? '.demo' : ''}.split_payment`;
    var econtractDoctypeForPath = (demo) ? 'starstix_econtract_demo' : 'starstix_econtract';
    var econtractDoctypePath = `dividers.sales_force.doctype.${econtractDoctypeForPath}.${econtractDoctypeForPath}`;

    const contractPubPath = (demo) ? 'starstix_econtract_webform_demo' : 'starstix_econtract_webform';
    const pub_id = `${contractPubPath}-${eContractName}`;


    if (Object.keys(query_params).length > 1) {
        const args = frappe.utils.get_url_from_dict({name: eContractName});
        location.replace(`${location.pathname}?${args}`);
    }

    if ((eContractDoctype && eContractName)) {
        const introEl = document.getElementById('introduction');
        introEl.innerHTML = `Contract ID partial: <strong>${eContractName.slice(-5)}</strong>`;
        showForm(eContractDoctype, eContractName);
    }

    document.querySelector("body").style.display = "block";

    function showForm() {
        const collector = new CollectSplitPayment(splitPaymentMethodPath, eContractDoctype, eContractName, demo);
        collector.showPaymentForm();
    };

    /**
     * Send a keep_alive signal so the sales rep knows the advertiser
     * is reviewing the document
     */
    function setAliveIndicator() {
        const payload = {
            doctype: eContractDoctype,
            docname: eContractDocname,
            event: 'keep_alive',
        }
        if (ws.readyState > ws.OPEN) {
            // attempt to reconnect
            ws = dividers.utils.setupWebSocket();
            setTimeout(() => {
                setAliveIndicator();
            }, 500);
        }
        if (ws.readyState < ws.OPEN) {
            setTimeout(() => {
                console.debug('waiting for websocket connection');
                setAliveIndicator();
            }, 500);
            return;
        }
        ws.send(JSON.stringify(payload));
        setTimeout(() => {
            console.debug('keep_alive');
            setAliveIndicator();
        }, 3000);
    };

    /**
     * Setup socketIO to listen for instructions for the client
     */
    function setupSocketIO() {
        if (!frappe.socketio.socket) {
            // socketio should work even for Guests
            frappe.require("/assets/frappe/js/lib/socket.io.min.js");
            frappe.require("/assets/frappe/js/frappe/socketio_client.js");
            frappe.socketio.init();
        }

        frappe.realtime.on(
            pub_id,
            ({msg, type}) => {
                console.debug('realtime data', type, msg);
                switch(type) {
                    case 'alert':
                        frappe.show_alert({message: msg, indicator: 'yellow'}, 10);
                        break;
                    case 'refresh':
                        var d = new frappe.ui.Dialog({
                            title: __("Confirm"),
                            primary_action_label: __("Reload Page"),
                            primary_action: () => {
                                location.reload();
                                d.hide();
                            },
                            secondary_action_label: __("No"),
                            secondary_action: () => d.hide(),
                        });

                        d.$body.append(`<p class="frappe-confirm-message">${msg}</p>`);
                        d.show();

                        // flag, used to bind "okay" on enter
                        d.confirm_dialog = true;

                        return d;
                    case 'notice':
                        frappe.msgprint(msg, 'Notice');
                }
            });
        console.info(`subscribed to ${pub_id}`);
    };

    function setupOn() {
        const realtimeFields = [
            'continuation_acknowledgement',
            'non_cancelable_acknowledgement',
            'exclusivity_acknowledgement',
            'signature',
            'full_name',
        ];
        const recordChange = frappe.utils.debounce((fieldname, newValue) => {
            const getRealtimeValues = () => {
                const rtValues = {};
                for (const fieldname of realtimeFields) {
                    rtValues[fieldname] = frappe.web_form.doc[fieldname] || '';
                }
                return rtValues;
            }

            frappe.call({
                method: `${econtractDoctypePath}.realtime_field_update`,
                args: {dn: eContractName, realtime_values: getRealtimeValues()},
            });
        }, 200, false);

        for (const field of realtimeFields) {
            if (frappe.web_form.has_field(field)) {
                frappe.web_form.on(field, recordChange);
            }
        }
    };

    function placeAcknowledgementFields() {
        for (const [fn, f] of Object.entries(frappe.web_form.fields_dict)) {
            if (fn.endsWith('_acknowledgement')) {
                const placeEl = document.querySelector(`.acknowledgement[data-fieldname=${fn}`);
                if (frappe.web_form.doc.docstatus == 1) {
                    f.wrapper.remove();
                } else if (placeEl) {
                    placeEl.appendChild(f.wrapper);
                }
            }
        }
    };
});


class CollectSplitPayment {
    get splitPaymentsNotice() {
        return `<div class="mb-4">
            <div class="form-message blue align-top">
                <div class="header">
                    Split Payments
                </div>
                <div>Today's deposit and all future payments will
                    be split evenly between the payment methods
                    provided.</div>
            </div>
            <div style="max-width:300px;margin:0 auto">
                ${this.paymentInfo.payment_table}
            </div>
        </div>
        `;
    }

    get paymentChargedOn() {
        return '';
        console.debug('paymentOptions', this.paymentOption);

        const paymentOptions = this.paymentOption.options;
        let paymentDate = '';
        switch (this.paymentOption.name) {
            case 'pay_now':
                return '';
            case 'zero_down':
                paymentDate = frappe.datetime
                .str_to_obj(paymentOptions.first_installment_date)
                .toLocaleDateString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                break;
            case 'delayed_payment':
                paymentDate = frappe.datetime
                .str_to_obj(paymentOptions.payment_date)
                .toLocaleDateString('en-us', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                });
                break;
        }

        const first = (paymentOptions.split > 1)
            ? ' first'
            : '';

        if (paymentDate !== '') {
            return `<div class="payment-charge-date mb-6 form-message green">
                Your${first} payment will be charged on:<br><strong>${paymentDate}</strong>.
            </div>`;
        }
        return '';
    }

    constructor(splitPaymentMethodPath, eContractDoctype, eContractName, demo) {
        this.splitPaymentMethodPath = splitPaymentMethodPath;
        this.eContractDoctype = eContractDoctype;
        this.eContractName = eContractName;
        this.paymentContainer = document.getElementById('split-payment-container');
        this.paymentInfo = null;
        this.eContractModified = null;
        this.demo = demo;
    }

    setUpPageUnload() {
        const unlockContractForm = () => this.unlockContractForm();
        window.addEventListener("beforeunload", unlockContractForm, false);
    }

    unlockContractForm() {
        frappe.call({
            type: 'POST',
            method: `${this.splitPaymentMethodPath}.unlock_econtract_form`,
            args: {starstix_econtract: this.eContractName},
        });
    }

    showPaymentForm() {
        this.fetchContractPaymentInformation();
    }

    fetchContractPaymentInformation() {
        frappe.freeze(`
            Retrieving payment information...
            <div class='h-100 d-flex align-items-center justify-content-center'>
                <div class='spinner'>
                    ${frappe.utils.icon('setting-gear', 'xl')}
                </div>
            </div>
        `);
        var errorDuringFrappeCall = true;
        frappe.call({
            type           : 'POST',
            method         : `${this.splitPaymentMethodPath}.fetch_contract_deposit_payments`,
            args           : {econtract_name: this.eContractName},
            callback       : ({message: paymentInfo}) => {
                errorDuringFrappeCall = false;
                // console.debug('paymentInfo', paymentInfo);
                this.eContractModified = paymentInfo.econtract_modified_date;
                this.paymentInfo = paymentInfo;
                this.beginPaymentProcess();
            },
            always: r => {
                setTimeout(() => {
                    if (errorDuringFrappeCall) {
                        frappe.throw(`<div class="mb-4">
                                There was an error retriving payment
                                information.
                            </div>
                            <h5>What can I do?</h5>
                            <div>
                                Please try again later. If the problem
                                persists, please contact your
                                Sales Representative.
                            </div>`);
                    }
                }, 500);
                window.saving = false;
                frappe.unfreeze();
            }
        });
    }

    /**
     * Begin the payment process by requesting that the server initialize
     * the payment with the payment gateway and returning the necessary
     * information to continue the process here.
     */
    beginPaymentProcess() {
        // Generate a payment request, then begin the payment collection process
        var errorDuringFrappeCall = true;
        this.setUpPageUnload();
        frappe.call({
            type           : 'POST',
            method         : `${this.splitPaymentMethodPath}.initialize_deposit`,
            args           : {
                docname            : this.eContractName,
                webform_modified   : this.eContractModified,
                split_payment_form : true,
            },
            freeze         : true,
            freeze_message : 'Preparing secure payment...',
            callback       : ({message: response}) => {
                errorDuringFrappeCall = false;
                if (response.error) {
                    frappe.throw(response.error);
                    this.unlockContractForm();
                }

                const gateway = response.gateway;
                const r = response.response;
                
                switch (gateway) {
                case 'Authorize.Net':
                    this.merchantCustomerId = response.merchant_customer_id;
                    this.paymentPageType = response.type;
                    this.authorizeNetToken = r.token;
                    this.formPostUrl = r.formPostUrl;
                    Object.assign(this, AuthorizeNetPaymentPlugin);
                    break;
                // case 'anotherGateway':
                //     if (r.error) {frappe.throw(r.error);}
                //     Object.assign(this, anotherGatewayPaymentPlugin);
                //     break;
                default:
                    console.error(`Unknown payment gateway "${gateway}"`);
                    frappe.throw('Unknown payment gateway');
                }

                this.displayPayment();
            },
            always: r => {
                setTimeout(() => {
                    if (errorDuringFrappeCall) {
                        frappe.throw(`<div class="mb-4">
                                There was an error communicating with the
                                payment gateway.
                            </div>
                            <h5>What can I do?</h5>
                            <div>
                                Please try again later, or contact your
                                Sales Representative to arrange an alternate
                                form of payment.
                            </div>`);
                    }
                }, 500);
                window.saving = false;
            },
        });
    }

    /**
     * Display the payment form
     **/
    displayPayment() {
        const message = `<div id="payment-msgprint-container">
            ${this.demoPaymentInstructions()}
            ${this.splitPaymentsNotice}
            ${this.paymentChargedOn}
            ${this.paymentElement()}
            ${this.displayFakeFooterButtons ? this.fakeFooterButtons : ''}
        </div>`;
        window.el = this.paymentContainer;
        window.el.innerHTML = message;

        dividers.waitForEl('#payment-msgprint-container')
        .then((el) => {
            this.postDisplayPaymentMsgBox();
        });
    };

    /**
     * Record a payment
     * 
     * @param {Object} transaction Response
     */
    recordPayment(transactResponse) {
        console.debug(`transactResponse for ${this.eContractName}`, transactResponse);
        window.saving = true;  // we're still saving
        frappe.freeze('Recording Payment...');
        frappe.call({
            type   : 'POST',
            method : `${this.splitPaymentMethodPath}.add_payment`,
            args   : {
                econtract_name : this.eContractName,
                payment_data   : transactResponse
            },
            callback : () => {
                frappe.msgprint({
                    title: 'Thank you for your payment',
                    message: `<div class='mb-4'>
                            Your payment has been submitted.
                            You may now close this window.
                        </div>
                        <div>Thank you for your business!</div>`,
                    clear: true,
                    indicator: false,
                });
                frappe.msg_dialog.custom_onhide = () => frappe.freeze('Thank you for your business!');
            },
            always   : r => {
                frappe.unfreeze();
                window.saving = false;
            },
        });
    };
}