
	frappe.templates['task_cust_info'] = `<div class="clearfix"></div>
<div class="address-box form-links">
    <div class="form-link-title">
        <span>Customer Information</span>
    </div>
    {% if (!customer) { %}
    <p>There is no Customer information associated with this Task</p>
    {% } else { %}

    <div class="row">
        <div class="col-3" style="font-weight:500;">Customer</div>
        <div class="col">
            <a
                href="{%= frappe.utils.get_form_link(\"Customer\", customer.name) %}"
                data-doctype="Customer"
                data-name="{%= customer.name %}"
            >{%= customer.name %}</a>
        </div>
    </div>
    {% if (contact) { %}
    <div class="row">
        <div class="col-3" style="font-weight:500;">Contact</div>
        <div class="col">
            {%
                const first = ("first_name" in contact) ? contact.first_name : "No first name";
                const last = ("last_name" in contact) ? contact.last_name : "No last name";
            %}
            <a
                href="{%= frappe.utils.get_form_link(\"Contact\", contact.name) %}"
                data-doctype="Contact"
                data-name="{%= contact.name %}"
            >{%= first %} {%= last %}</a>
        </div>
    </div>
    {% if (contact.phone) { %}
    <div class="row">
        <div class="col-3" style="font-weight:500;">Phone</div>
        <div class="col">{%= contact.phone %}</div>
    </div>
    {% } %}
    {% if (contact.mobile_no) { %}
    <div class="row">
        <div class="col-3" style="font-weight:500;">Mobile No</div>
        <div class="col">{%= contact.mobile_no %}</div>
    </div>
    {% } %}
    {% if (contact.email_id) { %}
    <div class="row">
        <div class="col-3" style="font-weight:500;">Email</div>
        <div class="col">{%= contact.email_id %}</div>
    {% } %}
    {% } %}
    </div>
    {% } %}
</div>
`;
