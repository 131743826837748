frappe.provide('dividers');

dividers = {
    cycleEpochs: {
        renewalSalesClose  : 'renewal_sales_close',
        salesClose         : 'sales_close',
        finalsDue          : 'finals_due',
        gangsDue           : 'gangs_due',
        installationStarts : 'installation_starts',
        cycleStarts        : 'cycle_starts',
    },

    cycle: {
        /**
         * Validate that a short (4-digit) cycle is valid
         *
         * @param {String} cycle    The cycle number
         *
         * @raise Error if cycle fails validation
         */
        validateShort: (cycle) => dividers.cycle.validate(cycle, 'short'),

        /**
         * Validate that a long (6-digit) cycle is valid
         *
         * @param {String} cycle    The cycle number
         *
         * @raise Error if cycle fails validation
         */
        validateLong: (cycle) => dividers.cycle.validate(cycle, 'long'),

        /**
         * Validate that the cycle is valid
         *
         * @param {String} cycle    The cycle number
         * @param {Enum} length     The length of the cycle: long (6 chars)
         *                          or short (4 chars)
         *
         * @raise Error if cycle fails validation
         */
        validate: (cycle, length='long') => {
            const validateCycleFragment = (cycle) => {
                const fragment = parseInt(cycle.slice(-2));
                if (fragment >= 1 && fragment <= 13) return;
                frappe.throw(__(`Invalid cycle: ${cycle} (Cycle number must fall between 1-13)`));
            }

            cycle = String(cycle);

            if (isNaN(cycle)) {
                frappe.throw(__(`Invalid cycle: ${cycle} (Cycle must be a number)`));
            }

            let firstCycle;
            let cycleLength = -1;
            if (length === 'short') {
                cycleLength = 4;
                firstCycle = '1913';
            } else if (length === 'long') {
                cycleLength = 6;
                firstCycle = '201913';
            }

            if (cycle < firstCycle) {
                frappe.throw(__(`Invalid cycle: ${cycle} (${firstCycle} is the first cycle)`));
            }

            if (cycle.length !== cycleLength) {
                frappe.throw(__(`Invalid cycle: ${cycle} (Cycle must have ${cycleLength} characters)`));
            }
            validateCycleFragment(cycle);
        },

        /**
         * Increment a cycle by the specified amount
         * 
         * @param {String} cycle        The cycle to increment
         * @param {Integer} increments  The amount to increment
         * @param {Enum} length     The length of the cycle: long (6 chars)
         *                          or short (4 chars)
         * 
         * @return {String} The new cycle
         * 
         * @raise Error if cycle fails validation
         */
        incrementCycle: (cycle, increments=1, length='long') => {
            dividers.cycle.validate(cycle, length);

            let cycleNum, cycleYear;
            cycleYear = parseInt(cycle.slice(0, cycle.length - 2));
            cycleNum = parseInt(cycle.slice(cycle.length - 2));
            cycleNum += increments;

            cycleYear += Math.floor(cycleNum / 13);
            cycleNum = cycleNum % 13;

            if (cycleNum === 0) {
              cycleNum = 13;
              cycleYear -= 1;
            }

            const zeroPad = (num, places) => String(num).padStart(places, '0');
            return `${cycleYear}${zeroPad(cycleNum, 2)}`;
        },

        cycleDiff: (minuend, subtrahend, length='long') => {
            minuend = String(minuend);
            subtrahend = String(subtrahend);
            dividers.cycle.validate(minuend, length);
            dividers.cycle.validate(subtrahend, length);

            const yearMinuend = parseInt(minuend.slice(0, minuend.length - 2));
            const cycleMinuend = parseInt(minuend.slice(minuend.length - 2));
            const yearSubtrahend = parseInt(subtrahend.slice(0, subtrahend.length - 2));
            const cycleSubtrahend = parseInt(subtrahend.slice(subtrahend.length - 2));

            const yearDiff = yearMinuend - yearSubtrahend;
            const cycleDiff = cycleMinuend - cycleSubtrahend;

            return (yearDiff * 13) + cycleDiff;
        },
    },

    utils: {
        /**
         * Connect to our custom websocket server
         */
        setupWebSocket() {
            const protocol = (location.protocol === 'https:') ? 'wss:' : 'ws:';
            const host = location.host;
            const path = 'dividers_ws';
            return new WebSocket(`${protocol}//${host}/${path}`);
        },

        setUpRedirectOnDocRename(docname) {
            frappe.realtime.on('redirect_on_rename', data => {
                console.info('redirecting using data', data);
                if (data.docname === docname) window.location = data.redirect;
            });
        },

        setupJumpMenu(frm, jumpFields) {
            frm.add_custom_button(__('Top'), () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'}), __('Jump'));
            for (const field of jumpFields) {
                if (field.customScrollFn) {
                    frm.add_custom_button(field.label, () => field.customScrollFn(field.fn), __('Jump'));
                } else {
                    frm.add_custom_button(field.label, () => dividers.utils.scrollTo(field.fn), __('Jump'));
                }
            }
        },

        isSalesForceUser: () => {
            return (frappe.user.name !== 'Administrator'
                    && frappe.user.has_role('Sales Force User'));
        },

        getFormLink_targetBlank: (doctype, name, html, display_text, query_parmas_obj, color) => {
            const link = frappe.utils
                .get_form_link(doctype, name, false, display_text, query_parmas_obj);
            if (!html) return link;
            let style='';
            if (color) style = `style="color:${color}"`;
            // target="_blank" frequently doens't work
            return `<a href="${link}" onclick="window.open('${link}', '_blank'); return false;" ${style}>${display_text || name}</a>`;
        },

        getContinuationPayments: (dividers_contract, starting='1999-01-01') => {
            return frappe.db.get_list('Dividers Contract Payment', {
                filters: {
                    dividers_contract,
                    payment_for: 'Autorenewal',
                    payment_date: ['>=', starting],
                },
                fields: ['paid_amount'],
            }).then(r => {
                if (r.length < 1) return 0;
                return Math.round(
                    (
                        r.reduce((a, b) => a.paid_amount + b.paid_amount)
                        + Number.EPSILON
                    ) * 100
                ) / 100;
            });
        },

        /**
         * Scroll to the indicated field _without_ focus
         * 
         * @param {String} fieldname    A Document fieldname
         */
        scrollTo: (fieldname) => {
            let field = cur_frm.get_field(fieldname);
            if (!field) return;

            let $el = field.$wrapper;

            // uncollapse section
            if (field.section.is_collapsed()) {
                field.section.collapse(false);
            }

            // scroll to input
            frappe.utils.scroll_to($el, true, 15);
        },

        /**
         * Run the cb EVERY TIME a tab is displayed
         *
         * To run only once, see runOnceOnTabDisplay
         *
         * @param {string} tabFieldName     The machine name of the tab
         * @param {function} cb             The function to run (accepts the event parameter)
         */
        runAlwaysOnTabDisplay: (frm, tabFieldName, cb) => {
            frm.get_active_tab().layout.tabs.filter(t => t.df.fieldname === tabFieldName)[0]
            .tab_link.find('.nav-link').on('shown.bs.tab', (e) => cb(e));
        },

        /**
         * Run the cb ONCE when a tab is displayed
         *
         * To run every time, see runAlwaysOnTabDisplay
         *
         * @param {string} tabFieldName     The machine name of the tab
         * @param {function} cb             The function to run (accepts the event parameter)
         */
        runOnceOnTabDisplay: (frm, tabFieldName, cb) => {
            frm.get_active_tab().layout.tabs.filter(t => t.df.fieldname === tabFieldName)[0]
            .tab_link.find('.nav-link').one('shown.bs.tab', (e) => cb(e));
        },

        formatPhoneNumber: (value) => {
            var cleaned = ('' + value).replace(/\D/g, '');
            var match = cleaned.match(/^(1)?(\d{1,3})?(\d{1,3})?(\d{1,4})?(\d*)?$/);
            if (match) {
                const intlCode = (match[1] ? '+1 ' : '');
                let areaCode = (match[2] ? `(${match[2]}` : '');
                let phonePrefix = (match[3] ? match[3] : '');
                if (phonePrefix !== '') {
                    areaCode += ') ';
                }
                const lineNumber = (match[4] ? match[4] : '');
                if (lineNumber !== '') {
                    phonePrefix += '-';
                }
                return  `${intlCode}${areaCode}${phonePrefix}${lineNumber}`;
            }
            return value;
        },

        maskPhoneNumberInputField: (frm, field) => {
            const formatPhoneFieldInputEvent = (e) => {
                // backspace, delete
                if (e?.inputType) {
                    if (!e.inputType.startsWith('delete')) {
                        e.target.value = dividers.utils.formatPhoneNumber(e.target.value);
                    }
                }
            }
            try {
                frm.fields_dict[field].input.addEventListener('input', formatPhoneFieldInputEvent);
            } catch(e) {
                console.error(`failed to mask field ${field}`, e);
            }
        },

        clearTableFields: (frm, tableFields) => {
            const clearTableField = field => {
                frm.set_value(field, []);
                frm.refresh_field(field);
            };
            if (typeof(tableFields) === 'string') tableFields = [tableFields];
            tableFields.forEach(fieldName => frm.set_value(fieldName, []));
        },

        /**
         * This can be used with `after_datatable_render(datatable)` to freeze column rows
         * in frappe's script reporting system.
         * 
         * Not that this is very hacky and causes weird bugs with the column UI.
         * Frappe datatable has an old feature request to add freeze functionality.
         * This dependency should be removed if they ever get around solving that issue.
         *
         * @param {object} datatable    The datatable
         * @param {int} freezeColumns   The number of columns to freeze
         */
        freezeDatatableColumns: (datatable, freezeColumns) => {
            const removeElementsBySel = (sel) => {
                document.head.querySelectorAll(sel).forEach(el => el.remove())
            }

            let observer = new MutationObserver(function(mutations) {
                // Since frappe.datatable uses transformX to make the header scroll,
                // we can't use postion:sticky to "pin" the frozen column headers.
                // Use a mutation observer to "un-transformX" the frozen header columns
                // instead.
                var transform;
                for (const mutation of mutations) {
                    if (mutation.type !== 'attributes') continue;
                    transform = mutation.target.style.transform;
                }
                const cellTransform = transform.replace('-', '');

                for (var i = 1; i <= freezeColumns; i++) {
                    datatable.header
                    .querySelectorAll(`.dt-row-header .dt-cell:nth-child(${i}), .dt-row-filter .dt-cell:nth-child(${i})`)
                    .forEach(el => el.style.transform = cellTransform);
                }
            });

            // Make the initial `freezeColumns` columns sticky in the datatable
            // and add basic styles
            const datatableClass = 'freeze-datatable-columns';

            let prefix = Array.from(datatable.datatableWrapper.classList)
                .filter(cls => cls.includes('dt-instance'))[0];
            let cssStyles = '';
            let sels = [];
            nextOffsetWidth = 0;
            for (var i = 1; i <= freezeColumns; i++) {
                const sel = `.${prefix} .dt-row .dt-cell:nth-child(${i})`;
                if (i < freezeColumns) {
                    cssStyles += `${sel} {left: ${nextOffsetWidth}px;}`;
                } else {
                    cssStyles += `${sel} {
                        left: ${nextOffsetWidth}px;
                        border-right: 2px solid var(--dt-border-color);
                    }`;
                }
                nextOffsetWidth += datatable.bodyScrollable.querySelector(sel).offsetWidth;
                sels.push(sel);
            }
            cssStyles += `${sels.join(',')} {position: sticky; z-index: 1;}`;
            removeElementsBySel(`.${datatableClass}.${prefix}`)
            let styleEl = document.createElement('style');
            styleEl.classList.add(datatableClass);
            styleEl.classList.add(prefix);
            styleEl.innerHTML = cssStyles;
            document.head.appendChild(styleEl);

            observer.observe(datatable.header, {attributes: true, attributeFilter: ['style']});
        }
    },

    /**
     * Return a promise to fetch the end cycle
     * 
     * @param {string}  cycle   The start cycle
     * @param {int}     length  The length of the reservation
     * @returns {promise}   The incremented cycle
     */
    fetchEndCycle: (cycle, length) => {
        length -= 1  // we want the end cycle, not the cycle after it
        return frappe.call({
            method: 'dividers.lib.cycle.increment_cycle',
            args: {cycle: cycle, increments: length, short: true},
        }).then(r => r.message);
    },

    /**
     * Return a promise to fetch the cycle's start date
     * 
     * @param {string}  cycle   The cycle who's start date to fetch
     * @returns {promise:string}    The cycle start date
     */
    fetchCycleStart: (cycle) => {
        if (!cycle || cycle.length !== 4) {
            return new Promise(resolve => resolve(''));
        }
        return frappe.call({
            method: 'dividers.lib.cycle.get_cycle_deadline',
            args: {
                cycle: cycle,
                deadline_name: dividers.cycleEpochs.cycleStarts,
            },
        }).then(r => moment(r.message).format(frappe.defaultDateFormat));
    },

    /**
     * Return a promise to fetch the end of the cycle
     * 
     * @param {string}  cycle   The cycle who's end date to fetch
     * @returns {promise:string}    The cycle end date
     */
    fetchCycleEndDate: (cycle) => {
        if (!cycle || cycle.length !== 4) {
            return new Promise(resolve => resolve(''));
        }
        return frappe.call({
            method: 'dividers.lib.cycle.get_cycle_start_end',
            args: {cycle},
        }).then(r => moment(r.message[1]).format(frappe.defaultDateFormat));
    },

    /**
     * Return a promise to fetch the end of the cycle
     * 
     * @param {string}  cycle   The cycle who's end date to fetch
     * @returns {promise:string}    The cycle end date
     */
    fetchCycleDeadline: (cycle, deadline_name='') => {
        if (!cycle || cycle.length !== 4) {
            return new Promise(resolve => resolve(''));
        }
        return frappe.call({
            method: 'dividers.lib.cycle.get_cycle_deadline',
            args: {cycle, deadline_name},
        }).then(r => moment(r.message).format(frappe.defaultDateFormat));
    },

    damerauLevenshtein: (a, b) => {
        var i;
        var j;
        var cost;
        var d = new Array();
     
        if (a.length == 0) {
            return b.length;
        }
     
        if (b.length == 0) {
            return a.length;
        }
     
        for (i = 0; i <= a.length; i++) {
            d[ i ] = new Array();
            d[ i ][ 0 ] = i;
        }
     
        for (j = 0; j <= b.length; j++) {
            d[ 0 ][ j ] = j;
        }
     
        for (i = 1; i <= a.length; i++) {
            for (j = 1; j <= b.length; j++) {
                if (a.charAt( i - 1 ) == b.charAt( j - 1 )) {
                    cost = 0;
                } else {
                    cost = 1;
                }
     
                d[ i ][ j ] = Math.min( d[ i - 1 ][ j ] + 1, d[ i ][ j - 1 ] + 1, d[ i - 1 ][ j - 1 ] + cost );
                
                if (
                    i > 1 && 
                    j > 1 &&  
                    a.charAt(i - 1) == b.charAt(j-2) && 
                    a.charAt(i-2) == b.charAt(j-1)
                ) {
                    d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost)
                }
            }
        }
     
        return d[a.length][b.length];
    },

    /**
     * Add and event to an ancestor that calls a callback when an event on
     * the child occurs. Does not require the child element to exist when
     * creating the event, so it works well in dynamic situations.
     * 
     * @param {DOMNode} parent          The element to add the event to
     * @param {String} eventName        The triggering event
     * @param {String} childSelector    The selector of the element to watch
     * @param {Function} cb             The function to run on the event
     * @param {Object} options          Options to provide addEvent Listner
     */
    addEventForChild: function(parent, eventName, childSelector, cb, options){      
        if (!options) options = {};
        parent.addEventListener(eventName, function(e){
            const mye = e;
            const matchingChild = e.target.closest(childSelector);
            if (matchingChild) cb(e);
        }, options);
    },

    /**
     * Wait for an element with `sel` to exist on the page, then return 
     * any elements that match.
     * 
     * Doesn't work well for adding events to an element, for that, use
     * dividers.addEventForChild.
     *
     * @param {String} sel     A selector
     * 
     * @return {Promise:NodeList}  Matching elements
     */
    waitForEl: (sel) => {
        return new Promise(resolve => {
            const test = document.querySelectorAll(sel);
            if (test.length) return resolve(test);

            const observer = new MutationObserver(mutations => {
                const test = document.querySelectorAll(sel);
                if (test.length) {
                    observer.disconnect();
                    resolve(test);
                }
            });

            observer.observe(document.body, {childList: true, subtree: true});
        });
    },
}
