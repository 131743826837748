frappe.views.KanbanView = class KanbanView extends frappe.views.KanbanView {
	setup_defaults() {
		return super.setup_defaults().then((r) => {
			return frappe.model.user_settings.get(this.doctype).then(userSettings => {
				this.kanban_column_stacking = userSettings.kanban_column_stacking;

				var menuLabel, displayState;
				if ((userSettings.kanban_column_stacking || 'on') === 'on') {
					menuLabel = 'Unstack Kanban Lists';
					displayState = 'off';
				} else {
					menuLabel = 'Stack Kanban Lists';
					displayState = 'on';
				}
				this.menu_items.push({
					label: menuLabel, 
					action: () => {
						frappe.model.user_settings.save(
							this.doctype, 'kanban_column_stacking', displayState);
						this.save_view_user_settings({
							stacking: displayState
						});
						window.location.reload();
					}
				});
				return r;
			});
		});
	}

	before_render() {
		this.dividersExtendKanbanStyle();
		super.before_render();
	}

	dividersExtendKanbanStyle() {
		frappe.model.user_settings
			.get(this.doctype)
			.then(userSettings => {
				var displayState = userSettings.kanban_column_stacking || 'on';
				if (displayState === 'on'
					&& $('#kanban-wraparound').length === 0
				) {
					var style = document.createElement('style');
					style.id = 'kanban-wraparound';
					style.innerHTML = `
	.kanban {
	    flex-wrap: wrap;
	    row-gap: 20px;
	}
	.kanban .kanban-column {
		min-height: initial;
	}
	.kanban .kanban-column .kanban-cards {
		min-height: 250px;
	    max-height: calc(50vh - 250px);
	}
					`;
					document.head.appendChild(style);
				}
			});
	}
}
