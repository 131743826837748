const AuthorizeNetPaymentPlugin = {
    get displayFakeFooterButtons() {
        // this form includes it's own cancel and submit buttons
        return false;
    },
    get paymentIframeName() {
        return 'authorize_net_payment';
    },
    get paymentTokenFormId() {
        return 'authorize-net-send-token-form';
    },

    setupCommunicationHandler() {
        window.CommunicationHandler = {
            onReceiveCommunication: (queryArgs, referrer, rawQueryString) => {
                console.debug('Communication Received from iframe communicator', queryArgs, referrer);
                switch(queryArgs.action) {
                case 'successfulSave':
                    console.debug('called successfulSave');
                    console.debug('referrer', referrer);
                    this.recordPayment({
                        'savedPaymentMethodToProfile': true,
                        'merchant_customer_id': this.merchantCustomerId,
                    });
                    break;
                case 'cancel':
                    console.debug('called cancel');
                    this.payFormMsgBox.hide();
                    window.saving = false;
                    break;
                case 'transactResponse':
                    console.debug('called transactResponse');
                    /**
                     * We frequently see ampersands as part of the business
                     * name for our customers, but Authorize.Net doesn't
                     * urlencode their query string. This causes
                     * `parseQueryString` to cut the response parameter in
                     * the middle of the string, giving us malformed JSON.
                     * 
                     * I have opted to handle this by assuming that our
                     * JSON, and only our JSON, contains curly braces,
                     * and extract it manually from the raw query string.
                     * This should be more reliable than the following:
                     *
                     * const response = JSON.parse(queryArgs.response);
                     **/
                    const start = rawQueryString.indexOf('{');
                    const end = rawQueryString.lastIndexOf('}') + 1;
                    const payload = rawQueryString.slice(start, end);
                    const response = JSON.parse(payload);
                    console.debug('response', response);
                    this.recordPayment(response);
                    // var response = params {float}["response"]; the value to split
                    // document.getElementById("token").value = response;
                    break;
                case 'resizeWindow':
                    console.debug('called resizeWindow');
                    // var w = parseInt(queryArgs.width);
                    var h = parseInt(queryArgs.height);
                    window.CommunicationHandler.resizedToWidth = window.innerWidth;
                    var iframe = document.getElementById(this.paymentIframeName);
                    iframe.style.height = h.toString() + "px";
                    // iframe.style.width = w.toString() + "px";
                    // centerPopup();
                    frappe.freeze_count = 1; // ensure that unfreeze takes effect
                    frappe.unfreeze();
                    break;
                }
            },
        };
    },

    demoPaymentInstructions() {
        return (this.demo)
            ? `<div class="demo-instructions mb-6">
                <div class="mb-4 cc-container">
                    <table class="table table-sm small mb-0">
                        <thead>
                            <tr><th colspan="4">
                                <div class="text-center">
                                    Test Credit Card Numbers
                                </div>
                            </th>
                            <tr><th>Description</th><th>Card Number</th><th>Expiration</th><th>CVC</th></tr>
                        </thead>
                        <body>
                            <tr>
                                <td>Visa</td>
                                <td class="copyable" onclick="frappe.utils.copy_to_clipboard('4007000000027')">
                                    4007000000027 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                                </td>
                                <td>future date</td>
                                <td>any</td>
                            </tr>
                            <tr>
                                <td>Mastercard</td>
                                <td class="copyable" onclick="frappe.utils.copy_to_clipboard('5424000000000015')">
                                    5424000000000015 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                                </td>
                                <td>future date</td>
                                <td>any</td>
                            </tr>
                            <tr>
                                <td>American Express</td>
                                <td class="copyable" onclick="frappe.utils.copy_to_clipboard('370000000000002')">
                                    370000000000002 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                                </td>
                                <td>future date</td>
                                <td>any</td>
                            </tr>
                            <tr>
                                <td>Discover</td>
                                <td class="copyable" onclick="frappe.utils.copy_to_clipboard('6011000000000012')">
                                    6011000000000012 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                                </td>
                                <td>future date</td>
                                <td>any</td>
                            </tr>
                        </body>
                    </table>
                </div>
                <table class="table table-sm small">
                    <thead>
                        <tr><th colspan="4">
                            <div class="text-center">
                                Test ACH Numbers
                            </div>
                        </th>
                        <tr><th>Description</th><th>Account Number</th><th>Routing Number</th></tr>
                    </thead>
                    <body>
                        <tr>
                            <td>Success: Amount < $100</td>
                            <td class="copyable" onclick="frappe.utils.copy_to_clipboard('000123456789')">
                                000123456789 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                            </td>
                            <td class="copyable" onclick="frappe.utils.copy_to_clipboard('021000021')">
                                021000021 <span>${frappe.utils.icon('duplicate', 'sm')}</span>
                            </td>
                        </tr>
                    </body>
                </table>
            </div>`
            : '';
    },

    authorizeNetLogo() {
        return `<div style="text-align:center;">
            <a
                href="https://www.authorize.net/"
                style="display:inline-block;width:140px;"
                target="_blank"
            >
                <img
                    src="https://www.authorize.net/content/dam/anet-redesign/reseller/authorizenet-200x50.png"
                    border="0"
                    alt="Authorize.net Logo"
                    width="200"
                    height="50"
                />
            </a>
            <br>
            <div style="font-size:0.7em;">
                Authorize.net and the Authorize.net logo are
                trademarks of CyberSource Corporation.
            </div>
        </div>`;
    },

    paymentElement() {
        // <450, >673
        // >=576, >=992
        let paymentContainerClass = 'payment-page';
        if (this.paymentPageType === 'profile_page_token') {
            paymentContainerClass = 'profile-page';
        }
        return `
            <div class="payment-container ${paymentContainerClass} pb-4">
                <div id="iframe_holder" class="authorize-net center-block">
                    <iframe id="${this.paymentIframeName}"
                            class="embed-responsive-item panel"
                            name="${this.paymentIframeName}"
                            width="100%"
                            frameborder="0"
                            scrolling="no"
                            hidden="true"
                    ></iframe>
                </div>
                <form id="authorize-net-send-token-form"
                      action="${this.formPostUrl}"
                      method="post"
                      target="${this.paymentIframeName}"
                >
                    <input type="hidden" name="token" value="${this.authorizeNetToken}" />
                </form>
                ${this.authorizeNetLogo()}
            </div>`;
    },

    postDisplayPaymentMsgBox() {
        var currentWidth;
        const loadIframe = () => {
            frappe.freeze(`
                Please wait (this can take a while)...
                <div class='h-100 d-flex align-items-center justify-content-center'>
                    <div class='spinner'>
                        ${frappe.utils.icon('setting-gear', 'xl')}
                    </div>
                </div>
            `);
            document.getElementById(this.paymentIframeName).removeAttribute('hidden');
            document.getElementById(this.paymentTokenFormId).submit();
        };

        const resizeHandler = frappe.utils.debounce(e => {
            const body = document.getElementsByTagName('body')[0];
            let secondBreakpoint = false;
            let breakpoint = 992;
            let breakpoint2 = 0;
            if (body.dataset.path.includes('split_payment')) {
                breakpoint = 768;
                secondBreakpoint = true;
                breakpoint2 = 1072;
            }

            if ((window.CommunicationHandler.resizedToWidth >= breakpoint && window.innerWidth < breakpoint) ||
                (window.CommunicationHandler.resizedToWidth < breakpoint && window.innerWidth >= breakpoint)
            ) {
                loadIframe();
            }
            if ((secondBreakpoint && window.CommunicationHandler.resizedToWidth >= breakpoint2 && window.innerWidth < breakpoint2) ||
                (secondBreakpoint && window.CommunicationHandler.resizedToWidth < breakpoint2 && window.innerWidth >= breakpoint2)
            ) {
                loadIframe();
            }
        }, 400, false);

        this.setupCommunicationHandler();
        addEventListener('resize', resizeHandler);
        loadIframe();
    },

    /**
     * Process the payment Authorize.net
     * 
     * @param {Object} transactResponse Response from Authorize.net
     **/
    submitContract(transactResponse) {
        window.saving = true;  // we're still saving
        frappe.freeze('Submitting your payment information...');

        this.payFormMsgBox.$wrapper.one('hidden.bs.modal', () => {
            // wait for the modal to close before submitting data or
            // displaying errors so that we can display an error here,
            // or so that errors posted by the server can display
            this.submitSignatureCB(transactResponse);
        });

        window.saving = false;
        this.payFormMsgBox.hide();
        frappe.unfreeze();
    }
}

export default AuthorizeNetPaymentPlugin;