
	frappe.templates['subscription_details'] = `<div class="clearfix"></div>
<div class="address-box">
    <p class="h6 flex align-center">
        {%= name %}
    </p>
    <p>
        <div class="flex">
            <span class="pr-1">{%= __("Party Type") %}:</span>
            <span>{%= party_type %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Party") %}:</span>
            <span>{%= party %}</span>
        </div>
    </p>
    <p>
        <div class="flex">
            <span class="pr-1">{%= __("Company") %}:</span>
            <span>{%= company %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Status") %}:</span>
            <span>{%= status %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Subscription Start Date") %}:</span>
            <span>{%= start_date %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Invoice Period") %}:</span>
            <span>{%= current_invoice_start %} - {%= current_invoice_end %}</span>
        </div>
        <div class="flex">
            <span class="pr-1">{%= __("Total") %}:</span>
            <span>$ {%= total %}</span>
        </div>
    </p>
</div>
`;
