
	frappe.templates['task_store_info'] = `<div class="clearfix"></div>
{%
const all_divider_types = Array.from(new Set(stores.map(s => s.divider_type || "Unknown"))).sort();
const dt_plural = (all_divider_types.length > 1) ? "s" : "";
%}
<div class="address-box form-links">
    <div class="form-link-title">
        <span>Divider Type{%= dt_plural %}</span>
    </div>
    {% if (all_divider_types.length < 1) { %}
    <p>There are no stores associated with this Project\'s Sales Order</p>
    {% } else { %}
    <ul style="list-style:none; padding-left:0;">
        {% for (const divider_type of all_divider_types) { %}
        <li>
            {% if (divider_type !== "Unknown") { %}
                {%= frappe.utils.get_form_link("Dividers Store Divider Type", divider_type, true) %}
            {% } else { %}
                Unknown
            {% } %}
            <ul>
            {%
            if (divider_type !== "Unknown") {
                filtered_stores = stores.filter(s => s.divider_type === divider_type)
            } else {
                filtered_stores = stores.filter(s => (!s.divider_type))
            }
            %}
            {% for (const store of filtered_stores) { %}
                <li>
                    <a
                        href="{%= frappe.utils.get_form_link(\"Dividers Store\", store.name) %}"
                        data-doctype="Dividers Store"
                        data-name="{%= store.name %}"
                    >{%= store.name %}: {%= store.banner %}</a>
                </li>
            {% } %}
            </ul>
        </li>
        {% } %}
    </ul>
    {% } %}
</div>

`;
