if (window.dev_server && frappe && frappe.boot) {
    frappe.boot.socketio_port = '443';
}

document.addEventListener("DOMContentLoaded", function(){
    /**
     * Display select change logs to users with the 'Show Change Log' role
     * see frappe/frappe/public/js/frappe/desk.js:80
     */
    setTimeout(() => {
        if (!frappe.user_roles.includes('System Manager')
            && frappe.user_roles.includes('Show Change Log')
        ) {
            const allowedChangeLogApps = ['Dividers'];
            if (frappe.boot.change_log) {
                frappe.boot.change_log = frappe.boot.change_log
                    .filter(l => allowedChangeLogApps.includes(l.title));
                frappe.app.show_change_log();
            }
        }
    }, 2000);


    /**
     * Set up route handlers to observe when the route changes
     */
    const observeRouteChange = () => {
        var allObservers = [];

        const setUpRouteObserver = () => {
            const target = document.getElementsByTagName('body')[0];
            const observerConfig = {
                attributes: true,
                attributeFilter: ['data-route'],
            };
            const observer = new MutationObserver((mutations, observer) => {
                const route = mutations[0].target.dataset.route;
                for (const observer of allObservers) {
                    observer.disconnect();
                }
                allObservers = [];

                /**
                 * Automatically select the "Communication" button when
                 * on a form page to filter out history form Document Activity
                 */
                if (route.startsWith('Form/')) {
                    dividers
                        .waitForEl('.timeline-item.activity-title input[type="checkbox"]')
                        .then((els) => {
                            let el = els[0];
                            el.checked = false;
                            el.dispatchEvent(new Event('click', {bubbles: false}));
                        });
                }
            });
            observer.observe(target, observerConfig);
        }

        setUpRouteObserver();
    }
    observeRouteChange();
});