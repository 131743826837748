
	frappe.templates['dividers_contract'] = `<div class="clearfix"></div>
<div class="address-box" style="max-height:200px;overflow-y:auto;">
	<p class="h6 flex align-center">
		<a
			href="{%= frappe.utils.get_form_link(\"Dividers Contract\", name) %}"
            data-doctype="Dividers Contract"
            data-name="{%= name %}"
		>{%= name %}</a>
	</p>
	<p>
		<div class="flex">
			<span class="pr-1">{%= __("Customer") %}: </span>
			<span>
				<a
					href="{%= frappe.utils.get_form_link(\"Customer\", customer) %}"
		            data-doctype="Customer"
		            data-name="{%= customer %}"
				>{%= customer %}</a>
			</span>
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Contact") %}: </span>
			<span>
				{% if (typeof contact_person !== "undefined") { %}
				<a
					href="{%= frappe.utils.get_form_link(\"Contact\", contact_person) %}"
		            data-doctype="Contact"
		            data-name="{%= contact_person %}"
				>{%= contact_person %}</a>
				{% } else { %}
					No Contact defined
				{% } %}
			</span>
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Contact Name") %}: </span>
			{% if (typeof contact_person !== "undefined") { %}
			{% if (typeof contact_display !== "undefined") { %}
			<span>{%= contact_display %}</span>
			{% } else { %}
			<span>Error - contact_display is undefined</span>
			{% } %}
			{% } else { %}
				No Contact defined
			{% } %}
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Production No") %}: </span>
			<span>{%= production_number %}</span>
		</div>
	</p><p>
		<div class="flex">
			<span class="pr-1">{%= __("Transaction Date") %}: </span>
			<span>{%= moment(transaction_date).format("M/D/YY") %}</span>
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Company") %}: </span>
			<span>
				<a
					href="{%= frappe.utils.get_form_link(\"Company\", company) %}"
		            data-doctype="Company"
		            data-name="{%= company %}"
				>{%= company %}</a>
			</span>
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Continuation") %}: </span>
        	{% if (autorenewal) { %}
        		{% if (typeof cancellation_date !== "undefined" && cancellation_date) { %}
				<span>Cancelled ({%= moment(cancellation_date).format("M/D/YY") %})</span>
        		{% } else { %}
				<span>Yes</span>
	    		{% } %}
        	{% } else { %}
			<span>No</span>
	    	{% } %}
		</div>
		<div class="flex">
			<span class="pr-1">{%= __("Status") %}: </span>
			<span>{%= status %}</span>
		</div>
	</p>
	<hr>
	<p>
		<div class="flex">
			<span class="pr-1">{%= __("Sales Partner") %}: </span>
			<span>
				<a
					href="{%= frappe.utils.get_form_link(\"Company\", company) %}"
		            data-doctype="Company"
		            data-name="{%= company %}"
				>{%= company %}</a>
			</span>
		</div>
	    {% if (sales_team.length > 0) { %}
	    <div class="flex">
	        <span class="pr-1">{%= __("Sales&nbsp;Team") %}:</span>
	        <span>
	            {% for (const rep of sales_team) { %}
					<a
						href="{%= frappe.utils.get_form_link(\"Sales Person\", rep.sales_person) %}"
			            data-doctype="Sales Person"
			            data-name="{%= rep.sales_person %}"
					>{%= rep.sales_person %}</a><br>
	            {% } %}
	        </span>
	    </div>
	    {% } %}
	</p>
	<p>
        {% if (payment_terms_template) { %}
        <div class="flex">
            <span class="pr-1">{%= __("Payment Terms") %}:</span>
            <span>{%= payment_terms_template %}</span>
        </div>
        {% } %}
        <div class="flex">
            <span class="pr-1">{%= __("Grand Total") %}:</span>
            <span>{%= format_currency(grand_total) %}</span>
        </div>
        {% if (autorenewal) {
        	const last = autorenewal_billing_schedule.length - 1;
        	const schedule = autorenewal_billing_schedule[last];
        %}
        <div class="flex">
            <span class="pr-1">{%= __("Continuation") %}:</span>
            <span>{%= format_currency(schedule.autorenewal_amount) %}</span>
        </div>
        {% } %}
    </p>
</div>`;
