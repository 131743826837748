document.addEventListener("DOMContentLoaded", function(){
    if (!dividers.utils.isSalesForceUser()) return;

    var allObservers = [];

    /**
     * Observe a node and hide any elements that match a selector
     * 
     * @param {DomNode} parent  A DomNode to base queries off of
     * @param {String} sel      The selector to find nodes to hide
     * 
     * @return {MutationObserver} The observer
     */
    const hideMatchesObserver = (parent, sel) => {
        const observerConfig = {childList: true, subtree: true};
        const observer = new MutationObserver((mutations, observer) => {
            for (const el of document.querySelectorAll(sel)) {
                el.style.setProperty('display', 'none', 'important');
            }
        });
        observer.observe(parent, observerConfig);
        return observer;
    }

    /**
     * Observe a document list and remove comment indicators
     */
    const setUpListObserver = () => {
        dividers.waitForEl('.frappe-list .result').then(() => {
            const listEl = document.querySelector('.frappe-list .result');
            allObservers.push(hideMatchesObserver(listEl, '.comment-count'));
        });
    }

    const setUpRouteObserver = () => {
        var listObserver = null;

        const target = document.getElementsByTagName('body')[0];
        const observerConfig = {
            attributes: true,
            attributeFilter: ['data-route'],
        };
        const observer = new MutationObserver((mutations, observer) => {
            const route = mutations[0].target.dataset.route;
            for (const observer of allObservers) {
                observer.disconnect();
            }
            allObservers = [];

            // allow comments on Leads
            if (route.includes('/Lead/')) return;

            if (route.startsWith('List/')) {
                const listObserver = setUpListObserver();
            }
            if (route.startsWith('Form/')) {
                dividers.waitForEl('.overlay-sidebar .comments').then((els) => {
                    // hide comments in the sidebar
                    let el = els[0];
                    el.style.setProperty('display', 'none', 'important')
                    el.previousElementSibling.style.setProperty('display', 'none', 'important')
                });
                // hide comments and activity
                dividers.waitForEl('.form-footer')
                .then((els) => els[0].style.setProperty('display', 'none', 'important'));

                // additionally, table fields are also set to read_only for both Lead and Address
                // in their respective documents
            }
        });
        observer.observe(target, observerConfig);
    }

    setUpRouteObserver();
});