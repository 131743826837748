
	frappe.templates['contract_reservation_summary'] = `<div class="dividers-contract-reservations">
    <div class="reservation-extensions-table">
        <div class="header-border-bottom"></div>
        <div class="simple-table">
            <table class="reservation-extensions">
                <thead>
                    <th width="43px">No.</th>
                    <th>Reservation</th>
                    <th>Type</th>
                    <th>Period</th>
                    <th>Length</th>
                    <th>Notes</ht>
                </thead>
                <tbody>
                    {%
                        var rowLength = reservation_details.reservations.length;
                        var rowIdx = 0;
                        for (const r of reservation_details.reservations) {
                            rowIdx++;
                            const cExt = reservation_details.contract_extensions[r.reservation] || [];
                            const aExt = reservation_details.autorenewal_extensions[r.reservation] || [];

                            const autorenewal = r.autorenewal === 1;
                            const enabled = r.enabled === 1;
                            const reserve_inventory_space = r.reserve_inventory_space === 1;
                            const original_reservation = r.original_reservation === 1;
                            const exclusive = r.reservation_is_exclusive === 1;

                            const periodName = r.period_type + "s";
                            const period = (l, opts={}) => {
                                l = parseInt(l);
                                let periodString = (l < 2 && l != 0)
                                        ? r.period_type
                                        : r.period_type + "s";
                                if (opts.lowercase) periodString = periodString.toLowerCase();
                                if (opts.asArray) return [l, periodString];
                                return l + " " + periodString;
                            };

                            const lengthToDate = (r.period_type === "Cycle")
                                ? parseInt(r.cycle_length_to_date)
                                : parseInt(r.month_length_to_date);

                            var rowspan = 1;
                            if (r.autorenewal === 1) rowspan++;
                            rowspan += cExt.length;
                            rowspan += aExt.length;

                            var storeRowClasses = ["store-row"];
                            if (!enabled) storeRowClasses.push("disabled");
                            if (rowIdx === rowLength) storeRowClasses.push("last-row");
                    %}
                    <!-- reservation -->
                    <tr class="{%= storeRowClasses.join(\" \") %}">
                    	<td rowspan="{%= rowspan %}">{%= r.idx %}</td>
                    	<td rowspan="{%= rowspan %}">
                            <span class="text-nowrap">
                                <strong>
                                    <a
                                        href="{%= frappe.utils.get_form_link(\"Dividers Store\", r.dividers_store) %}"
                                        data-doctype="Dividers Store"
                                        data-name="{%= r.dividers_store %}"
                                    >
                                        {%= r.dividers_store %}
                                    </a>
                                </strong>
                                (<a
                                    href="{%= frappe.utils.get_form_link(\"Dividers Reservation\", r.reservation) %}"
                                    data-doctype="Dividers Reservation"
                                    data-name="{%= r.reservation %}"
                                >{%= r.reservation %}</a>)
                            </span>

                            {% if (r.tags || !reserve_inventory_space) { %}<br><em>Tags:
                                {% if (!reserve_inventory_space) { %}
                                    TIF{%= (r.tags) ? "," : "" %}
                                {% } %}
                                {%= r.tags %}
                            </em>{% } %}

                            <br>
                                {%
                                    var style = "";
                                    if (r.status !== "Active") style = "background:yellow;border:1px solid red;";
                                %}
                                <em style="{%= style %}">Status: {%= r.status %}</em>
                            <hr style="margin:3px 0px">
                            {%= r.store_banner %}
                            <br><span style="white-space: pre">{%= r.primary_address %}</span>
                        </td>
                    	<td>Contract&nbsp;Term</td>
                        <td class="text-nowrap">
                            {%= (r.period_type === "Cycle") ? "20" + r.start_cycle : r.start_date %}
                            :
                            {%= (r.period_type === "Cycle") ? "20" + r.base_end_cycle : r.base_end_date %}
                        </td>
                    	<td class="text-nowrap">{%= period(r.reservation_length) %}</td>
                        <td>
                            {%= r.spaces + " " + r.surface.toLowerCase() + "s" %}
                            {% if (r.adjusted_end_cycle) { %}
                            ends
                            {%= (r.period_type === "Cycle") ? "20" + r.adjusted_end_cycle : r.adjusted_end_date %}
                            {% } %}


                            {%= (enabled) ? "" : "<br>Reservation is disabled" %}
                            {%= (exclusive) ? "<br>Reservation is exclusive" : "" %}
                            {%= (reserve_inventory_space) ? "" : "<br>Reserved space is allowed to be sold" %}
                            {%= (original_reservation) ? "<br>Original store reservation" : "" %}
                        </td>
                    </tr>

                    <!-- contract extensions -->
                    {% for (const ext of cExt) { %}
                    <tr class="{%= (enabled) ? \"foo\" : \"disabled\" %}">
                        <td><em class="pl-3">{%= ext.type %}</em></td>
                        <td class="text-nowrap">
                            {%= (r.period_type === "Cycle") ? "20" + ext.start_cycle : ext.start_date %}
                            :
                            {%= (r.period_type === "Cycle") ? "20" + ext.end_cycle : ext.end_date %}
                        </td>
                        <td class="text-nowrap">{%= period(ext.extension_length) %}</td>
                        <td>{% if (ext.notes) { %}<q style="white-space:pre-line;">{%= ext.notes %}</q>{% } %}</td>
                    </tr>
                    {% } %}

                    <!-- autorenewal -->
                    {% if (autorenewal) { %}
                    <tr class="{%= (enabled) ? \"foo\" : \"disabled\" %}">
                        <td>Continuation</td>
                        <td class="text-nowrap">
                            {%= (r.period_type === "Cycle")
                                ? "20" + r.autorenewal_start_cycle
                                : r.autorenewal_start_date %}
                            :
                            {%= (!r.cancellation_date)
                                    ? "-"
                                    : (r.period_type === "Cycle")
                                        ? "20" + r.autorenewal_end_cycle
                                        : r.autorenewal_end_date
                            %}
                        </td>
                        <td>
                            {% if (r.cancellation_date) { %}
                            {%= (r.period_type === "Cycle")
                                ? r.cycle_autorenewal_length
                                : r.month_autorenewal_length %}
                            {%= periodName %}
                            {% } %}
                        </td>
                        <td>
                            {% if (lengthToDate > 0) { %}
                            {%= period(lengthToDate, {lowercase:true}) %} in continuation
                            {% } else { %}
                            {%= period(Math.abs(lengthToDate) + 1, {lowercase:true}) %} until continuation
                            {% } %}
                            <br>{%
                                var aextTotalLength = 0;
                                aExt.forEach(x => aextTotalLength += parseInt(x.extension_length));
                                if (aextTotalLength > 0) {
                                    const [arLength, arPeriod] = period(
                                        aextTotalLength,
                                        {lowercase:true, asArray:true});
                            %}

                            {%= arLength %} total {%= r.period_type.toLowerCase() %} extensions

                            {%  } %}

                            {% if (r.cancellation_date) { %}
                            <br>Cancelled on {%= frappe.format(r.cancellation_date, {fieldtype: "Date"}) %}
                            {% } %}
                        </td>
                    </tr>

                    <!-- autorenewal extensions -->
                    {% for (const ext of aExt) { %}
                    <tr class="{%= (enabled) ? \"foo\" : \"disabled\" %}">
                        <td><em class="pl-3">{%= ext.type %}</em></td>
                        <td class="text-nowrap">
                            {%= (r.period_type === "Cycle") ? "20" + ext.start_cycle : ext.start_date %}
                            :
                            {%= (r.period_type === "Cycle") ? "20" + ext.end_cycle : ext.end_date %}
                        </td>
                        <td class="text-nowrap">{%= period(ext.extension_length) %}</td>
                        <td>{% if (ext.notes) {  %}<q style="white-space:pre-line;">{%= ext.notes %}</q>{% } %}</td>
                    </tr>
                    {% } %}
                    {% } %}
                    {% } %}
                </tbody>
            </table>
        </div>
    </div>
</div>
`;
