document.addEventListener("DOMContentLoaded", function(){
	const old_set_party_dashboard_indicators = erpnext.utils.set_party_dashboard_indicators;
	$.extend(erpnext.utils, {
		set_party_dashboard_indicators: function(frm) {
			if(frm.doc.__onload && frm.doc.__onload.dashboard_info) {
				var company_wise_info = frm.doc.__onload.dashboard_info;
				if (company_wise_info.length > 1) {
					for (const info of company_wise_info) {
						this.add_indicator_for_multicompany(frm, info);
					}
				} else if (company_wise_info.length === 1) {
					old_set_party_dashboard_indicators(frm);
					frm.dashboard.add_indicator(__('Continuation Rate: {0}',
						[format_currency(company_wise_info[0].continuation_rate, company_wise_info[0].currency)]),
						'pink');
				}
			}
		},

		// almost identical to erpnext/erpnext/public/js/utils.js
		add_indicator_for_multicompany: function(frm, info) {
			frm.dashboard.stats_area.show();
			frm.dashboard.stats_area_row.addClass('flex');
			frm.dashboard.stats_area_row.css('flex-wrap', 'wrap');

			var color = info.total_unpaid ? 'orange' : 'green';

			var indicator = $('<div class="flex-column col-xs-6">'+
				'<div style="margin-top:10px"><h6>'+info.company+'</h6></div>'+

				'<div class="badge-link small" style="margin-bottom:10px"><span class="indicator blue">'+
				'Annual Billing: '+format_currency(info.billing_this_year, info.currency)+'</span></div>'+

				'<div class="badge-link small" style="margin-bottom:10px">'+
				'<span class="indicator '+color+'">Total Unpaid: '
				+format_currency(info.total_unpaid, info.currency)+'</span></div>'+

				'<div class="badge-link small" style="margin-bottom:10px">'+
				'<span class="indicator pink">Continuation Rate: '
				+format_currency(info.continuation_rate, info.currency)+'</span></div>'+

				'</div>').appendTo(frm.dashboard.stats_area_row);

			if(info.loyalty_points){
				$('<div class="badge-link small" style="margin-bottom:10px"><span class="indicator blue">'+
				'Loyalty Points: '+info.loyalty_points+'</span></div>').appendTo(indicator);
			}

			return indicator;
		},
	});
});