
	frappe.templates['resource_section'] = `<div class="form-links row row-cols-lg-2 row-cols-1">
{%
var sectionOpen = false;
const openSection = () => {
    if (sectionOpen) return;
    %} <div class="type-section col p-4"> {%
    sectionOpen = true;
};
const closeSection = () => {
    if (!sectionOpen) return;
    %} </div> {%
    sectionOpen = false;
};
for (const doc of rows) {
    if (doc.type === "Section") {
        closeSection();
        openSection();
        if (doc.title) {
%}
<div class="form-link-title mt-0">{%= doc.title %}</div>
{%
        }
    } else if (doc.type === "URL") {
        openSection();
%}
<a href="{%= doc.url %}" target="_blank">
    <div class="type-url document-link">
        <div class="type-url document-link-badge">
            {%= frappe.utils.icon("link-url") %}
            {%= doc.title %}
        </div>
    </div>
</a>
{%
    } else if (doc.type === "File" || doc.type === "Image") {
        const icon = doc.type.toLowerCase();
        openSection();
%}
<a href="{%= doc.file %}" target="_blank">
    <div class="type-url document-link">
        <div class="type-file document-link-badge">
            {%= frappe.utils.icon(icon) %}
            {%= doc.title %}
        </div>
    </div>
</a>
{%
    }
}
closeSection();
%}
</div>
`;
