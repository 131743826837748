
	frappe.templates['store_info'] = `<div class="clearfix"></div>
<div class="address-box">
    <p class="h6">
        {%= banner %}{% if (store_number) { %} #{%= store_number %}{% } %}
    </p>
    <p>{%= primary_address %}</p>
    <hr>
    <table>
        <tbody>
            {% if (chain !== null) { %}
            <tr>
                <td class="font-weight-bold">Chain:</td>
                <td class="pl-1">{%= chain %}</td>
            </tr>
            {% } %}
            {% if (divider_type !== null) { %}
            <tr>
                <td class="font-weight-bold">Divider Type:</td>
                <td class="pl-1">{%= divider_type %}</td>
            </tr>
            {% } %}
            {% if (checkout_lane_count !== null) { %}
            <tr>
                <td class="font-weight-bold">Lanes:</td>
                <td class="pl-1">{%= checkout_lane_count %}</td>
            </tr>
            {% } %}
            {% if (status !== null) { %}
            <tr style="{%= (status === "Active") ? "" : "background:yellow;border:1px solid red;" %}">
                <td class="font-weight-bold">Status:</td>
                <td class="pl-1">{%= status %}</td>
            </tr>
            {% } %}
            {% if (status_reason) { %}
            <tr style="background:yellow;border:1px solid red;">
                <td class="font-weight-bold">Reason for Status:</td>
                <td class="pl-1">{%= status_reason %}</td>
            </tr>
            {% } %}
            {% if (current_assignment) { %}
            <tr>
                <td class="font-weight-bold">Currently Assigned To:</td>
                <td class="pl-1">{%= current_assignment %}</td>
            </tr>
            {% } %}
        </tbody>
    </table>
</div>
`;
