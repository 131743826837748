import "./lib/cleave.min.js"
import "./erpnext_tweaks.js"
import "./dividers_utils.js"
import "./get_document_utils.js"
import "./map_defaults.js"
import "./tracking_url.js"
import "./reservations.js"
import "./kanban_tweeks.js"
import "./iso_tweaks.js"
import "./boot.js"
import "./timeclock.js"
import "./farmers.js"
import "./templates/resource_section.html"
import "./templates/contact.html"
import "./templates/sales_order_details.html"
import "./templates/subscription_details.html"
import "./templates/dividers_contract.html"
import "./templates/dividers_contract_reservations.html"
// import "./templates/campaign_reservations.html"
import "./templates/store_reservations.html"
import "./templates/store_info.html"
import "./templates/customer_info.html"
import "./templates/task_store_info.html"
import "./templates/task_cust_info.html"
import "./templates/reservation_extensions.html"
import "./templates/contract_reservation_summary.html"
import "./templates/farmers_agent_result.html"