
	frappe.templates['dividers_contract_reservations'] = `{%
var store_link;
reservations.sort((a, b) => {
    if (a.dividers_store < b.dividers_store) {
        return -1;
    }
    if (a.dividers_store > b.dividers_store) {
        return 1;
    }
    return 0;
});
%}
<div class="clearfix"></div>
<div class="address-box" style="max-height:200px;overflow-y:auto;">
<div class="dividers-contract-reservations">
    {% for (const rsvn of reservations) { %}
    <p class="h6 mb-1 flex">
        {% store_link = frappe.utils.get_form_link(\"Dividers Store\", rsvn.dividers_store) %}
        <a 
            href="{%= store_link %}"
            data-doctype="Dividers Store"
            data-name="{%= rsvn.dividers_store %}"
        >
            {%= rsvn.dividers_store %}
        </a>
    </p>
    <div class="ml-3">
        <div class="flex">
            <div class="flex pr-1" style="">{%= __("Contract:") %}</div>
            <div class="">
                {%= rsvn.start_cycle %}-{%= rsvn.reservation_end_cycle %}
                <br>({%= rsvn.spaces %} {%= rsvn.surface %}{% if (parseInt(rsvn.spaces) > 1) { %}s{% } %},
                {%= rsvn.reservation_length %}
                {%= rsvn.period_type %}{% if (parseInt(rsvn.reservation_length) > 1) { %}s{% } %})
            </div>
        </div>
        {% if (rsvn.autorenewal) { %}
        <div class="flex">
            <div class="flex pr-1" style="">{%= __("Autorenewal:") %}</div>
            <div class="">
                {%= rsvn.autorenewal_start_cycle %}{% if (rsvn.autorenewal_end_cycle) { %}-{% } %}{%= rsvn.autorenewal_end_cycle %}
                {% if (rsvn.cancellation_date) { %}
                    (Cx on {%= moment(rsvn.cancellation_date).format("M/D/YY") %})
                {% } %}
            </div>
        </div>
        {% } %}
    </div>
    {% } %}
</div>`;
